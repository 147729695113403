import _ from "lodash";
import Promise from 'bluebird';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Logo from '../../components/Logo';
import Loader from '../../components/Loader';
import * as feedItemsActions from '../../actions/feedItems';
import * as teamsActions from '../../actions/teams';
import * as offersActions from '../../actions/offers';
import * as propositionActions from '../../actions/propositions';
import * as picksActions from '../../actions/picks';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';

import Alerts from '../Alerts';
import AccountPopover from '../user/AccountPopover';
import BottomNavBar from '../navigation/BottomNavBar';
import TopNavBar from '../navigation/TopNavBar';
import HorizontalStepper from '../../components/HorizontalStepper';

import Instructions from './Instructions'
import DailyDeal from './DailyDeal'

function DashboardMain(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    offers,
    fetchOffers,
    picks,
    fetchPicks,
    propositions,
    fetchPropositions,
    teams,
    fetchTeams,
    history
  } = props;


  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let pollingId;

    if (teams.length == 0) fetchTeams()

    fetchData().finally(() => {
      setLoading(false)
      pollingId = pollData();
    })
    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(pollingId);
  }, []);

  let polledTimes = 0;

  const pollData = () => {
    const pollingId = setInterval(() => {
      fetchData();
    }, 1000 * 60);

    return pollingId;
  };

  const fetchData = async () => {
    console.log("fetching");

    fetchOffers()

    return await Promise.all([
      fetchFeedItems(user.id),
      fetchPicks()
    ])
  };

  if (!user.id) return <Navigate to="/" />;
  if (user.status != "activated") return <Navigate to="/onboarding/identity" />;
  if (!user.beta) return <Navigate to="/waitlist" />;

  return (
    <Box>
      <Box sx={{background: "#f7f7f7"}} minHeight={"100vh"}>
        <Box pt={2} onClick={() => history.push("/app")}>
          <Container maxWidth="md">
            <img
              src="https://res.cloudinary.com/dxxog3y9j/image/upload/v1726686197/gravy_1_we9oi7.png"
              style={{
                height: 40
              }}/>
          </Container>
        </Box>

        {
          loading ?
            <Loader /> :
            <Outlet />
        }
      </Box>

      <Alerts />

      <BottomNavBar />

      <Instructions />
      <DailyDeal />

    </Box>
  );
}


const select = $$state => _.pick($$state, "user", "feedItems", "picks", "propositions", "offers", "teams");
export default connect(select, {
  ...feedItemsActions,
  ...picksActions,
  ...propositionActions,
  ...offersActions,
  ...teamsActions,
})(withRouter(DashboardMain));
