import _ from "lodash";
import qs from 'qs';
import { connect } from 'react-redux';
import * as React from "react";
import { useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import withRouter from '../../utils/withRouter';

function PicksMain(props) {
  const { user, cards, fetchCards, history } = props;

  const [loading, setLoading] = React.useState(true);

  const onClose = () => history.goBack();

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const allowExternalConnect = plaidCard == null &&
    _.get(gravyCard, "balance", 0) < 10;

  return (
    <FullScreenDialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="body1" fontWeight={600}>
            How can you add new Picks?
          </Typography>
          <Typography variant="body2">
            Choose your adventure.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          loading ?
            <Loader /> :
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {

                allowExternalConnect &&
                  <ListItem disablePadding divider>
                    <ListItemButton onClick={() => history.push(`/app/account/cards/new`)}>
                      <ListItemIcon>
                        <CreditCardIcon />
                      </ListItemIcon>
                      <ListItemText primary={
                        <Typography variant="body2" fontWeight={600} color="primary">
                          Connect your credit card
                        </Typography>
                      } />
                    </ListItemButton>
                  </ListItem>
              }

              <ListItem disablePadding divider>
                <ListItemButton onClick={() => history.push(`/app/merchants`)}>
                  <ListItemIcon>
                    <AddShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={
                    <Typography variant="body2" fontWeight={600} color="primary">
                      Shop with your Gravy Card(s)
                    </Typography>
                  } />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(`/app/account/referrals/new`)}>
                  <ListItemIcon>
                    <EmojiPeopleIcon />
                  </ListItemIcon>

                  <ListItemText primary={
                    <Typography variant="body2" fontWeight={600} color="primary">
                      Refer friends to Gravy
                    </Typography>
                  } />
                </ListItemButton>
              </ListItem>
            </List>

        }
      </DialogContent>
      <DialogActions sx={{p: 2}}>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, "user", "cards");
export default connect(select, {
  ...cardActions,
})(withRouter(PicksMain));
