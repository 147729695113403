import _ from "lodash";
import * as React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import * as dates from "../../utils/dates";
import currencyFormatter from "../../utils/currencyFormatter";
import ClickablePaper from "../../components/ClickablePaper";

export default function FeedItemCard(props) {
  const { requirement_title, feed_item, expires_at, prize, proposition, created_at, status } = props;

  const { image_url } = (feed_item || {});

  return (
    <ClickablePaper variant="outlined">

      <Box>
        <Typography
          variant="body2"
          fontWeight={600}>
          {
            feed_item.title || proposition.event.title
          }
        </Typography>
        <Typography
          variant="body2">
          Save {currencyFormatter(prize.amount, 2)} if {
            proposition.win_description
          }
        </Typography>
      </Box>

        {
          _.includes(["activated"], status) &&
            <Stack direction="row" justifyContent="end" sx={{mt: 2}}>
              <Chip size="smalll" label={
                  <Typography variant="body4" fontWeight={600}  textTransform="uppercase">
                    shop ${requirement_title} before ${
                         dates.tzAwareDateTime(expires_at, "MM/DD")
                      }

                  </Typography>
                }/>
            </Stack>
        }
    </ClickablePaper>
  );
}
