import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Logo from '../../components/Logo';
import Link from '../../components/Link';

import withRouter from '../../utils/withRouter';

import SideNavBar from './SideNavBar';
import AuthButton from '../user/AuthButton';
import AccountPopover from '../user/AccountPopover';
import NotificationsPopover from './NotificationsPopover';


function TopNavBar(props) {
  const { history, location, user } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [scrolled, setScrolled] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box>

      <AppBar position={"fixed"} sx={{
        background: "transparent",
        opacity: "0.95",
        color: "text.primary",
        boxShadow: "none",
        borderBottom: "none",
        display: {
          xs: "none",
          md: "initial"
        },
        pt: 2,
        pb: 2
      }}>
        <Container maxWidth="lg">
          <Stack direction="row" alignItems="center"  sx={{
            justifyContent: "space-between",
          }}>

            <IconButton sx={{position: "relative", left: -10}}>
              <Avatar sx={{
                bgcolor: 'primary.main',
                height: 30,
                width: 30,
                fontWeight: 600
              }}>
                G
              </Avatar>
            </IconButton>
          </Stack>
        </Container>
      </AppBar>
    </Box>
  );
};

const select = $$state => _.pick($$state, "user");
export default connect(select, {

})(withRouter(TopNavBar));
