import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from '../../components/Loader';

import withRouter from '../../utils/withRouter';
import * as cardActions from '../../actions/cards';

const CardsMain = props => {
  const {
    user,
    cards,
    fetchCards,
  } = props;

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetchCards().finally(() => setLoading(false))
  }, [])

  return (
    <Box>
      {
        loading ?
          <Loader /> :
          <Outlet />
      }
    </Box>
  );
}

const select = $$state => _.pick($$state, ["cards"]);
export default connect(select, {
  ...cardActions,
})(withRouter(CardsMain));
