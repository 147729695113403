import _ from "lodash";
import qs from 'qs';
import Promise from 'bluebird';
import { connect } from 'react-redux';
import * as React from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as dates from "../../utils/dates";
import withRouter from '../../utils/withRouter';

import Card from '../cards/Card';
import Wallet from '../cards/Wallet';

function Offer(props) {
  const {dailyDeal, setDailyDeal, open, onClose} = props;
  const [saving, setSaving] = React.useState(false);

  const { merchant, proposition, prize } = dailyDeal;

  const activateDailyDeal = async () => {
    try {
      setSaving(true)

      const { data } = await request.put(`daily_deals/${dailyDeal.token}`, {
        status: "activated"
      })

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setDailyDeal({
          ...dailyDeal,
          status: "activated"
        })
      }

    } catch(err) {
      alert("There was an error, please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <FullScreenDialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box mt={2}>
          <img src={merchant.logo_url} style={{
            height: 50
          }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h4" fontWeight={600}>
            Free Pour at {merchant.name}
          </Typography>
          <Typography variant="body1" lineHeight={1.3}>
            Pours are free Gravy specials that do not require you
            to use your Gravy card beforehand.
          </Typography>
        </Box>

        <Divider />

        <Box mt={3}>
          <Typography variant="body2" fontWeight={600} >
            {currencyFormatter(prize, 0)} off your next { merchant.name } order
          </Typography>
          <Typography variant="body1" lineHeight={1.3}>
            If {proposition.title}
          </Typography>
        </Box>

        <Box mt={4}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <CalendarMonthIcon sx={{fontSize: 20}} />

              <Typography variant="body3"  fontWeight={500}>
                {proposition.event.title} ({dates.tzAwareDateTime(proposition.event.start_at, "MM/DD")})
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <AccessTimeIcon sx={{fontSize: 20}} />

              <Typography variant="body3"  fontWeight={500}>
                7 days to redeem if you win
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 2}}>
          <LoadingButton size="large" loading={saving} variant="contained" onClick={activateDailyDeal}>
            Activate
          </LoadingButton>

          <Button disabled={saving} onClick={onClose}>
            Not Interested
          </Button>
      </DialogActions>
    </FullScreenDialog>
  )
}

function Activated(props) {
  const {user, savePlaidUser, createAlert, dailyDeal, setDailyDeal, open, onClose} = props;

  const { merchant, proposition, prize } = dailyDeal;

  const connectToPlaid = async token => {
    try {
      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        createAlert({title: "Plaid connected"})
      }
    } catch(err) {
      console.log(err)
      alert("Unknown error please try again")
    }
  }

  return (
    <FullScreenDialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box mt={2}>
          <img src={merchant.logo_url} style={{
            height: 50
          }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h4" fontWeight={600}>
            How your refund works
          </Typography>
          <Typography variant="body1" lineHeight={1.3}>
            Connect your personal credit card with Plaid.
            If you shop at {merchant.name} within 7 days of winning,
            you'll automatically be credited.
          </Typography>
        </Box>

        <Box mt={4}>
          {
            user.plaid_user == null ?
              <PlaidLinkButton onSuccess={connectToPlaid} fullWidth /> :
              <Button fullWidth variant="contained" disabled>
                Plaid Successfully Connected
              </Button>
          }
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 2}}>
          <Button onClick={onClose}>
            Close
          </Button>
      </DialogActions>
    </FullScreenDialog>
  )
}

function DashboardDailyDeal(props) {
  const { user, savePlaidUser, createAlert, location } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [dailyDeal, setDailyDeal] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true);

  const toggleOpen = () => setOpen(!open)

  const fetchData = async () => {
    Promise.all([
      fetchDailyDeal()
    ]).finally(() => {
      setLoading(false)
    })
  }

  const fetchDailyDeal = async () => {
    const { data } = await request.get(`daily_deals/${search.d}`)
    if (data.errors) {

    } else {
      setDailyDeal({
        id: data.id,
        ...data.attributes
      })
    }
  }

  React.useState(() => {
    if (!!search.d) {
      toggleOpen()
      fetchData();
    }
  }, [])

  if (dailyDeal == null) return <Box />

  return dailyDeal.status == "pending" ?
    <Offer
      open={open}
      dailyDeal={dailyDeal}
      setDailyDeal={setDailyDeal}
      onClose={toggleOpen} /> :
    <Activated
      open={open}
      user={user}
      savePlaidUser={savePlaidUser}
      createAlert={createAlert}
      dailyDeal={dailyDeal}
      onClose={toggleOpen} />

}

const select = $$state => _.pick($$state, "user", "cards");
export default connect(select, {
  ...cardActions,
  ...userActions,
  ...alertActions
})(withRouter(DashboardDailyDeal));
