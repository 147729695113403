import _ from 'lodash';
import { connect } from 'react-redux';
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import withRouter from '../../utils/withRouter';
import * as transactionActions from '../../actions/transactions';
import currencyFormatter from '../../utils/currencyFormatter';
import { safeMoment } from '../../utils/dates';
import Loader from '../../components/Loader';

function TransactionsList(props) {
  const { transactions, fetchTransactions } = props;

  const [tab, setTab] = React.useState(0);
  const tabs = ["refunds", "withdrawals"];

  const filteredTransactions = _.filter(transactions, t => {
    return tab == 0 ?
      t.transactable_type == "OfferPrize" :
      t.transactable_type == "Payout"
  })

  return (
    <Box>
      <Tabs value={tab} onChange={(e, tab) => {
        setTab(tab)
      }}>
        {
          _.map(tabs, t => {
            return (
              <Tab key={t} sx={{
                textTransform: "inherit",
                fontWeight: 600,
                fontSize: 14
              }} label={
                _.capitalize(t)
              }  />
            )
          })
        }
      </Tabs>

      <Stack spacing={1}>
        {
          _.map(filteredTransactions, (transaction, i) => (
            <Paper
              key={i}
              variant="outlined"
              sx={{p: 2}}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2}>

                  <Box>
                    <Typography variant="body3" fontWeight={600}>
                      {
                        transaction.transactable.feed_item_title
                      }
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                      {
                        transaction.transactable.proposition_title
                      }
                    </Typography>

                    <Typography variant="body3" color="text.secondary">
                      {
                        safeMoment(transaction.created_at).format("MM/DD/YY")
                      }
                    </Typography>
                  </Box>
                </Stack>

                <Chip color={
                  transaction.direction == "credit" ? "error" : "success"
                } size="small" label={
                  <Typography variant="body4" fontWeight={600}>
                    { transaction.direction == "credit" ? "-" : "+"}{
                      currencyFormatter(transaction.amount, 2)
                    }
                  </Typography>

                } />
              </Stack>
            </Paper>
          )
        )}
      </Stack>
    </Box>
  )
}

const select = $$state => _.pick($$state, "transactions");
export default connect(select, {
  ...transactionActions
})(withRouter(TransactionsList));
