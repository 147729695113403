import _ from 'lodash';
import Promise from 'bluebird';
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { fCurrency } from '../../utils/format-number';

import Iconify from '../../components/iconify';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import card from '../../utils/raincard';
import request from '../../utils/request';
import * as environment from '../../environment';

import Actions from './Actions';

export default function Card(props) {
  const {
    id,
    name,
    source,
    amount,
    balance,
    issuer,
    funding,
    form,
    last_4,
    network,
    pan,
    cvc,
    expiration_month,
    expiration_year,
    secrets,
    fetchCardBalance,
    fetchCardNumbers,
    history,
    skipClick,
    sx
  } = props;

  const [loading, setLoading] = React.useState(form == "virtual");

  React.useEffect(() => {
    if (source == "gravy" && form == "virtual"){
      Promise.all([
        fetchCardNumbers(id),
        fetchCardBalance(id)
      ]).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, []);

  return (
    <ClickablePaper
      onClick={() => {
        if (source == "gravy" && !skipClick) history.push(`./${id}`)
      }}
      variant="outlined"
      sx={{
        px: {
          xs: 1,
          md: 3
        },
        height: 252,
        borderRadius: 2,
        position: 'relative',
        '.slick-slider, .slick-list, .slick-track, .slick-slide > div': {
          height: 1,
        },
        '&:before, &:after': {
          left: 0,
          mx: 2.5,
          right: 0,
          zIndex: -2,
          height: 200,
          bottom: -16,
          content: "''",
          opacity: 0.16,
          borderRadius: 2,
          bgcolor: 'grey.500',
          position: 'absolute',
        },
        '&:after': {
          mx: 1,
          bottom: -8,
          opacity: 0.24,
        },
        ...sx,
      }}
    >
      {
        loading ?
          <Loader /> :
          <Stack justifyContent="space-between" sx={{
            height: 1, p: {
              xs: 1,
              md: 3
            }
          }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                {
                  source == "plaid" ?
                    <Typography variant="body2">{name}</Typography> :
                    <Iconify width={50} icon="logos:visa" />

                }
              </Box>

              <Stack direction="row" alignItems="center" spacing={2}>
                {
                  source == "gravy" &&
                    <Box>
                      <Typography sx={{ typography: 'h3' }}>
                        {
                          fCurrency(balance || amount)
                        }
                      </Typography>
                    </Box>

                }
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>

              {
                !!expiration_month &&
                  <Box>
                    <Typography variant="body3" fontWeight={600}>Expires</Typography>
                    <Typography variant="body3">{
                      `${expiration_month}/${expiration_year}`
                    }</Typography>
                  </Box>

              }

              {
                !!last_4 &&
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}>

                      <Box>
                        <Typography variant="body3" fontWeight={600}>Number</Typography>
                        <Typography variant="body2">{
                          pan || `******${last_4}`
                        }</Typography>
                      </Box>

                    {
                      !!cvc &&
                        <Box>
                          <Typography variant="body3" fontWeight={600}>CVC</Typography>

                          <Typography variant="body2">{cvc}</Typography>
                        </Box>
                    }

                  </Stack>
              }

            </Stack>
          </Stack>
      }

    </ClickablePaper>
  );
}
