import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import GravyVideo from '../../components/GravyVideo';
import AuthButton from '../user/AuthButton';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import TopNavBar from '../marketing/TopNavBar';

function UserGroupMarketing(props) {
  const {
    params,
    user,
    history,
    location
  } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = React.useState(true);
  const [userGroup, setUserGroup] = React.useState({})

  React.useEffect(() => {
    if (!!params.userGroupId) fetchUserGroup(params.userGroupId).finally(() => setLoading(false))
  }, [params.userGroupId])

  const fetchUserGroup = async userGroupId => {
    const { data } = await request.get(`user_groups/${userGroupId}`)

    if (!!data.id) {
      setUserGroup({
        id: data.id,
        ...data.attributes
      })
    }
  };

  const joinGroup = async () => {
    if (!!user.id) {
      const { data} = await request.post(`user_groups/${userGroup.id}/users`)

      if (data.errors) {
        alert(data.errors.message)
      } else {
        history.push(`/app/account/groups/${userGroup.id}`)
      }
    } else {
      window.localStorage.setItem("user_group_id", userGroup.id);
      history.push("/onboarding");
    }
  }

  if (loading) return <Box />

  return (
    <Box minHeight={"100vh"} pb={12} sx={{background: "#f8f7fa"}}>
      <TopNavBar
        user={user}
        history={history}
        location={location} />

      <Box pt={{
        xs: 3,
        md: 3
      }} pb={{
        xs: 3,
        md: 3
      }} sx={{background: "#fff", borderBottom: "1px solid rgb(223 223 223 / 87%)"}}>
        <Container maxWidth="sm">
          <Stack direction={{
            xs: "row",
            md: "row"
          }}
          spacing={1}

          alignItems={{
            xs: "initial",
            md: "center"
          }}>

            <Box>
              <Typography variant="h1" fontWeight={600} style={{
                fontSize: mobile ? "1.1rem" : "1.25rem"
              }}>
                You've been invited to {userGroup.name} on Gravy
              </Typography>
              <Typography variant={mobile ? "body3" : "body2"} color="text.secondary" fontWeight={500}>
                Save big on everyday expenses when cool things happen in sports, culture, and the news.
              </Typography>
            </Box>

          </Stack>

            <Box mt={2}>
              <Button variant="contained" fullWidth size="large" onClick={joinGroup}>
                Join Group
              </Button>
            </Box>

        </Container>
      </Box>

      <Box mt={2}>
        <Container maxWidth="sm">
          <Grid container spacing={{xs: 2, sm: 2}}>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>

                <GravyVideo />
              </Stack>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "#fff",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Box p={1}>
          <Stack direction="row" alignItems="center" justifyContent={
            mobile ? "start" : "center"
          }>
            <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
              style={{
                height: 40,
                position: "relative",
                top: 3
              }} />
            <Link variant="body3" fontWeight={600} color="primary" component={RouterLink} to="/about" target="_blank">
              Built by the team behind Plaid ($13.5B)
            </Link>
          </Stack>
        </Box>
      </AppBar>

    </Box>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {

})(withRouter(UserGroupMarketing));
