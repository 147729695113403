import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';

import FeedItemCard from '../feedItems/Card';

export default function InstructionsShop(props) {
  const {
    user,
    feedItems,
    cards,
    picks,
    params,
    teams
  } = props;

  return (
    <Box>
      <Box>
        <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
          Use Picks to win cash back
        </Typography>
        <Typography variant="body1">
          Refunds on your purchases
          when cool stuff happen in sports, culture,
          and the news like...
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container spacing={1}>
          {

            _.chain(feedItems)
              .sortBy(item => {
                return item.feedableType === "Player" ? Number.MAX_VALUE : 0;
              }).take(3).map((feedItem, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <FeedItemCard
                    {...feedItem}
                    feedItem={feedItem}
                    user={user}
                    teams={teams} />
                </Grid>
              )
            }).value()

          }
        </Grid>
      </Box>

    </Box>
  );
}
