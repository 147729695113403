import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';

import PlaidLogo from '../../icons/PlaidLogo';

import FeedItemsList from '../feedItems/List';

function GradientCircularProgress({count}) {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>

      <CircularProgress
        variant="determinate"
        value={100} // Represents 100% (the full circle)
        size={120} // Adjust size as needed
        thickness={5} // Adjust thickness to your preference
        sx={{
          color: '#e0e0e0', // Light grey color for the empty portion
          position: 'absolute', // Make it overlap with the main circle
        }}
      />

      <CircularProgress
        variant="determinate"
        value={
          count > 5 ? 100 : 20 * count
        } // Represents the filled portion (e.g., 70%)
        size={120} // Same size as the background circle
        thickness={5} // Same thickness as the background circle
        sx={{
          'svg circle': { stroke: 'url(#my_gradient)' },
          position: 'absolute', // Layer on top of the background circle
        }}
      />
    </React.Fragment>
  );
}

const DashboardHome = props => {
  const {
    user,
    feedItems,
    picks,
    params,
    history,
    location,
    teams
  } = props;

  React.useEffect(() => {
    // Scroll to the top of the window on first render
    window.scrollTo(0, 0);
  }, []);

  const categories = [
    "coffee",
    "groceries"
  ];

  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="md">
        <Box>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="center" sx={{position: "relative", height: 120}}>
              <Box>
                <GradientCircularProgress count={10} />
              </Box>

              <Box sx={{
                position: "relative",
                top: 30,
                width: 120
              }}>
                <Typography variant="h1" textAlign="center" lineHeight={1}>
                  {picks.length}
                </Typography>
                <Typography variant="body2" textAlign="center" textTransform="uppercase" fontWeight={700}>
                  {
                    picks.length == 1 ?
                      "PICK" :
                      "PICKS"
                  }
                </Typography>

              </Box>
            </Stack>

            <Stack alignItems="center">
              <Button startIcon={<AddIcon />} variant="contained" size="large" component={RouterLink} to="/app/picks">
                Add Picks
              </Button>
            </Stack>
          </Stack>
        </Box>


        <Box mt={4}>
          <FeedItemsList />
        </Box>

      </Container>

    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "feedItems", "teams", "picks"]);
export default connect(select, {
  ...alertActions,
  ...userActions,
})(withRouter(DashboardHome));
