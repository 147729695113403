import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {
  Link as RouterLink,
} from "react-router-dom";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpIcon from '@mui/icons-material/Help';

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import * as time from "../../utils/time";
import * as dates from "../../utils/dates";
import currencyFormatter from "../../utils/currencyFormatter";
import FullScreenDialog from "../../components/FullScreenDialog";

import CreateError from './CreateError';

import { usePostHog } from "posthog-js/react";

export default function OffersCreate(props) {
  const {
    createOffer,
    feedItem,
    proposition,
    pick,
    open,
    onClose,
    history
  } = props;

  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);

  const posthog = usePostHog();

  const save = async () => {
    try {
      setSaving(proposition.id);

      const results = await createOffer({
        proposition_id: proposition.id,
        feed_item_id: feedItem.feedItem.id,
      })

      if (_.get(results, ["errors", "message"]) == "NO_PICKS") {
        setError("NO_PICKS")
      } else if (!!results.errors) {
        alert(results.errors.message)
      } else {
        onClose();

        history.push(`/app/offers`)
      }
    } catch(err) {
      alert(err.message)
    } finally {
      setSaving(null)
    }
  };

  if (proposition == null) return <Box />

  console.log(proposition)

  const prize = pick == null ?
    0 :
    _.min([
      pick.expected_value_cents / proposition.propositionProbabilities[0].probability,
      1000
    ]) / 100

  return (
    <FullScreenDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{
        px: 2,
        pb: 4,
        pt: 4,
        borderBottom: "1px solid #dddcdc"
      }}>

        <Box>
          <Typography variant="body1" fontWeight={500} lineHeight={1.3} mb={0.5}>
            {feedItem.title}
          </Typography>

          <Typography
            variant="h3"
            fontWeight={600}
            lineHeight={1.3}
            mb={0.5}>
            {
              currencyFormatter(prize, 2)
            } cash back if {proposition.winDescription}.
          </Typography>

        </Box>
      </DialogTitle>
      <DialogContent sx={{px: 2}}>

        <Box>
          <LoadingButton onClick={save} loading={saving} fullWidth variant="contained" size="large">
            Use your pick
          </LoadingButton>
        </Box>

        <Box mt={4} mb={6}>

          <Box mt={4}>
            <Typography variant="body3"  fontWeight={600} textTransform="uppercase" mb={1}>
              Details
            </Typography>
            <Stack spacing={1}>

              <Stack direction="row" spacing={2} alignItems="center">
                <AccessTimeIcon sx={{fontSize: 20}} />

                <Typography variant="body3"  fontWeight={500}>
                  Offer expires {dates.tzAwareDateTime(proposition.eligibilityEndsAt, "MM/DD hh:mm A")}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <CalendarMonthIcon sx={{fontSize: 20}} />

                <Typography variant="body3"  fontWeight={500}>
                  Event ends {dates.tzAwareDateTime(proposition.eligibilityEndsAt, "MM/DD hh:mm A")}
                </Typography>
              </Stack>
            </Stack>
          </Box>

        </Box>
      </DialogContent>
      <DialogActions sx={{
        py: 2,
        pl: 1,
        pr: 2,
        justifyContent: "space-between"
      }}>

          <Button onClick={onClose} startIcon={<ArrowBackIosNewIcon />} sx={{color: "black", fontWeight: 600}}>
            Back
          </Button>

      </DialogActions>

      <CreateError
        {...props}
        open={!!error}
        onClose={() => setError(null)}
        exitAll={onClose} />

    </FullScreenDialog>
  );
}
