import _ from "lodash";
import qs from 'qs';
import { connect } from 'react-redux';
import * as React from "react";
import { useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import * as merchantOfferActions from '../../actions/merchantOffers';
import withRouter from '../../utils/withRouter';

import Card from '../cards/Card';
import InstructionsShop from './InstructionsShop';
import InstructionsPick from './InstructionsPick';

function DashboardInstructions(props) {
  const { cards, fetchCards, fetchMerchantOffers, location } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [open, setOpen] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const card = gravyCard;

  const toggleOpen = () => setOpen(!open)

  const onboarded = window.localStorage.getItem("onboarded");

  React.useState(() => {
    if (!!onboarded) {
      window.localStorage.removeItem("onboarded");
      setTimeout(() => {
        toggleOpen()
      }, 2000)
    } else if (!!search.ins) {
      setTimeout(() => {
        toggleOpen()
      }, 2000)
    }
  }, [])

  React.useEffect(() => {
    if (!!onboarded || !!search.ins) {
      Promise.all([
        fetchCards(),
        fetchMerchantOffers()
      ]).finally(() => setLoading(false))
    }
  }, [])

  if (loading == null) return <Box />

  return (
    <FullScreenDialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box pt={2}>
          <Stack direction="row" spacing={2}>
            {
              _.times(2, i => (
                <Avatar sx={{
                    width: 25,
                    height: 25,
                    background: step == i ?
                       "black" :
                       "grey"
                  }}>
                  <Typography variant="body3" fontWeight={600}>
                    {i + 1}
                  </Typography>
                </Avatar>
              ))
            }
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {
            step == 0 &&
              <InstructionsShop {...props} card={card} next={() => setStep(1)} />
          }


          {
            step == 1 &&
              <InstructionsPick  {...props} {...card} />
          }

        </Box>
      </DialogContent>
      <DialogActions sx={{p: 2}}>
        {
          step == 0 && !card &&
            <Button size="large" variant="contained" onClick={() => {
              setStep(1)
            }}>
              Next
            </Button>
        }

        {
          step == 1 &&
            <Stack direction="row">
              <Button size="large" variant="contained" onClick={toggleOpen}>
                Got it
              </Button>
              <Button onClick={() => {
                setStep(step - 1)
              }}>
                Back
              </Button>
            </Stack>
        }
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, "user", "cards", "feedItems", "merchantOffers", "teams");
export default connect(select, {
  ...cardActions,
  ...merchantOfferActions
})(withRouter(DashboardInstructions));
