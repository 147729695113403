import _ from "lodash";
import * as React from "react";
import {connect} from 'react-redux';
import {Link as RouterLink, Outlet, Navigate, useLocation, useParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';

import * as feedItemsActions from '../../actions/feedItems';
import * as teamActions from '../../actions/teams';

import Card from './Card';
import DashboardTopNav from '../navigation/DashboardTopNav';

import {usePostHog} from "posthog-js/react";

const FeedItemsList = props => {
  const {
    user,
    feedItems,
    fetchFeedItems,
    teams,
    fetchTeams,
    history
  } = props;

    const [activeFeedItem, setActiveFeedItem] = React.useState({});
    const [tab, setTab] = React.useState(0);

    const posthog = usePostHog();

    const categories = ["all", "games", "players", "culture", "news", "crypto"];

    const relevantFeedItem = _.filter(feedItems, feedItem => {
      const desiredCategory = categories[tab];
      if (desiredCategory === "all") {
        return true;
      } else if (desiredCategory === "games") {
        return feedItem.category === "sports" && feedItem.feedableType === "SportsEvent";
      } else if (desiredCategory === "players") {
        return feedItem.category === "sports" && feedItem.feedableType === "Player";
      } else {
        return feedItem.category === desiredCategory;
      }
    });

    React.useEffect(() => {
      if (_.get(feedItems, ["length"]) === 0) {
        console.error(
          `FeedItems array is empty. User ID: ${
            props.user?.id
          }, ${new Date().toISOString()}`
        );
      }

    }, [feedItems]);


    React.useEffect(() => {
      // Scroll to the top of the window on first render
      window.scrollTo(0, 0);
    }, []);

    return (
      <Box>
        <Box mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" value={tab} onChange={(e, tab) => {
            setTab(tab)
          }}>
            {
              _.map(categories, t => {
                return (
                  <Tab key={t} sx={{
                    textTransform: "inherit",
                    fontWeight: 600,
                    fontSize: 14
                  }} label={
                    <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                      {
                        t
                      }
                    </Typography>
                    }  />
                )
              })
            }
          </Tabs>
        </Box>
            <Grid container spacing={1}>
                {
                    _.chain(relevantFeedItem)
                      .sortBy(item => {
                        return item.feedableType === "Player" ? item.propositions[0].teamId : '';
                      }).map((item, i) => (
                        <Grid key={i} xs={6} onClick={() => {
                            if (item.feedItem.id == null) {
                                posthog.capture('feed_item_clicked', {
                                    feed_item_id: item.id,
                                })
                            }

                            history.push(`./feeditems/${item.feedItem.id}`)
                        }}>
                            <Box p={0.5}>
                              <Card feedItem={item} user={user} teams={teams} />
                            </Box>
                        </Grid>
                    )).value()
                }
            </Grid>

          <Outlet />
        </Box>
    );
}

const select = $$state => _.pick($$state, ["user", "feedItems", "teams"]);
export default connect(select, {
    ...feedItemsActions,
    ...teamActions
})(withRouter(FeedItemsList));
