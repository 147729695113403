import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReceiptIcon from '@mui/icons-material/Receipt';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import * as time from '../../utils/time';
import currencyFormatter from '../../utils/currencyFormatter';

import * as userActions from '../../actions/user';
import * as offersActions from '../../actions/offers';

import Card from './Card';
import Details from './Details';

const OffersList = props => {
  const {
    offers,
    history
  } = props;

  return (
    <Box>
      <Stack spacing={1}>
          {
              _.map(offers, (offer, i) => (

              <Box key={i} onClick={() => {
                  history.push(`./${offer.id}`)
                }}>
                      <Card {...offer} />
                  </Box>
              ))
          }
      </Stack>

    </Box>
  )
}

const OffersListContainer = props => {
  const {
    user,
    offers,
    feedItems,
    fetchOffers,
    history
  } = props;

  const [offerId, setOfferId] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const tabs = ["pending", "won", "lost"];

  let relevantOffers;

  switch(tab) {
    case 0:
      relevantOffers = _.chain(offers)
        .filter(o => _.includes(["activated", "verified"], o.status))
        .reject(o => o.status == "activated" && time.getTimeTill(o.expires_at) == "Expired")
        .value();
      break;
    default:
      relevantOffers = _.filter(offers, p => p.status == tabs[tab])
  }


  return (
    <Box pb={10} pt={2}>
      <Box>
        <Typography variant="h3" fontWeight={700}>
          Your Picks
        </Typography>
        <Typography variant="body1" lineHeight={1.3} mt={1}>
          Your hopes for cash back are riding on these...
        </Typography>
      </Box>

      <Box mt={3} mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={tab} onChange={(e, tab) => {
          setTab(tab)
        }}>
          {
            _.map(tabs, t => {
              return (
                <Tab key={t} sx={{
                  textTransform: "inherit",
                  fontWeight: 600,
                  fontSize: 14
                }} label={
                  <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                    {
                      t
                    }
                  </Typography>
                  }  />
              )
            })
          }
        </Tabs>
      </Box>

      <OffersList
        history={history}
        offers={relevantOffers} />

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "offers", "feedItems"]);
export default connect(select, {
  ...userActions,
  ...offersActions,
})(withRouter(OffersListContainer));
