import _ from "lodash";
import Promise from 'bluebird';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import * as transactionActions from '../../actions/transactions';
import withRouter from '../../utils/withRouter';

import Details from './Details';
import Transactions from './Transactions';

function BalanceMain(props) {
  const { transactions, fetchTransactions } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    if (transactions.length == 0) fetchTransactions()
  }

  return (
    <Box pt={2} pb={10}>
      <Container maxWidth="sm">
        <Details />

        <Box mt={4}>
          <Transactions />
        </Box>
      </Container>

      <Outlet />
    </Box>
  );
}


const select = $$state => _.pick($$state, "transactions");
export default connect(select, {
  ...transactionActions
})(withRouter(BalanceMain));
