import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Summary from '../cards/Summary';
import ExpenseCategories from '../cards/ExpenseCategories';

import Alert from '../../components/Alert';
import Link from '../../components/Link';

export default function OverviewBankingView() {
  const theme = useTheme();

  return (
    <Box pt={2} pb={14}>
      <Container maxWidth={'sm'}>
        <Grid container spacing={1}>
          <Grid xs={12} md={6}>
              <Summary
                title="Purchases"
                icon="eva:diagonal-arrow-left-down-fill"
                percent={0}
                total={0}
                chart={{
                  series: [
                    { x: 2010, y: 88 },
                    { x: 2011, y: 120 },
                    { x: 2012, y: 156 },
                    { x: 2013, y: 123 },
                    { x: 2014, y: 88 },
                    { x: 2015, y: 66 },
                    { x: 2016, y: 45 },
                    { x: 2017, y: 29 },
                    { x: 2018, y: 45 },
                    { x: 2019, y: 88 },
                    { x: 2020, y: 132 },
                    { x: 2021, y: 146 },
                    { x: 2022, y: 169 },
                    { x: 2023, y: 184 },
                  ],
                }}
              />
          </Grid>
          <Grid xs={12} md={6}>
              <Summary
                title="Savings"
                color="success"
                icon="eva:diagonal-arrow-right-up-fill"
                percent={0}
                total={0}
                chart={{
                  series: [
                    { x: 2010, y: 88 },
                    { x: 2011, y: 120 },
                    { x: 2012, y: 156 },
                    { x: 2013, y: 123 },
                    { x: 2014, y: 88 },
                    { x: 2015, y: 166 },
                    { x: 2016, y: 145 },
                    { x: 2017, y: 129 },
                    { x: 2018, y: 145 },
                    { x: 2019, y: 188 },
                    { x: 2020, y: 132 },
                    { x: 2021, y: 146 },
                    { x: 2022, y: 169 },
                    { x: 2023, y: 184 },
                  ],
                }}
              />
          </Grid>

          <Grid xs={12} md={12}>
            <Stack spacing={3}>
              <ExpenseCategories
                title="Expenses Categories"
                chart={{
                  series: [
                    { label: 'Food', value: 14 },
                    { label: 'Transportation', value: 23 },
                    { label: 'Gas', value: 21 },
                    { label: 'Rent', value: 17 },
                    { label: 'Travel 5', value: 15 },
                  ],
                  colors: [
                    theme.palette.primary.main,
                    theme.palette.warning.dark,
                    theme.palette.success.darker,
                    theme.palette.error.main,
                    theme.palette.info.dark,
                    theme.palette.info.darker,
                    theme.palette.success.main,
                    theme.palette.warning.main,
                    theme.palette.info.main,
                  ],
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
