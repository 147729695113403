import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import withRouter from '../../utils/withRouter';
import * as transactionActions from '../../actions/transactions';

function PayoutsNew(props) {
  const { history, payout } = props;

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("/app/balance");

  const save = async () => {
    try {
      setSaving(true);

      const results = await payout()
      if (results.errors) {
        alert(results.errors.message)
      } else {
        alert("Success! You should have received a text message with a link to your payout")
        window.location.href = "/app/balance"
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body2" fontWeight={600}>
            Withdraw funds
          </Typography>

          <Typography variant="body3" color="text.secondary" fontWeight={500}>
            Gravy uses Tremendous to handle withdrawals.
            With Tremendous, you receive your funds to
            your bank account, Paypal, or a gift card.
          </Typography>
        </Box>

      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton loading={saving} onClick={save} variant="contained" color="primary">
          Complete
        </LoadingButton>
      </DialogActions>
    </Dialog>

  );
}

const select = $$state => _.pick($$state, "user", "transactions");
export default connect(select, {
  ...transactionActions
})(withRouter(PayoutsNew));
