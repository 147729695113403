import _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';

import AddMembers from './AddMembers';
import Members from './Members';
import Leaderboard from './Leaderboard';

function UserGroupDetails(props) {
  const {user, userGroups, params} = props;

  const [tab, setTab] = React.useState(0);
  const [addMembersOpen, setAddMembersOpen] = React.useState(false);

  const toggleAddMembers = () => setAddMembersOpen(!addMembersOpen);

  const userGroup = _.find(userGroups, g => g.id == params.userGroupId);

  React.useEffect(() => {
  }, []);

  const tabs = _.reject([
    // "activity",
    "members",
    "leaderboard",
  ], _.isNil);

  return (
    <Box pt={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1} direction="row" alignItems="center">
          {
            !!userGroup.emoji &&
              <Typography variant="h3" fontWeight={600}>
                {userGroup.emoji}
              </Typography>

          }

          <Typography variant="body1" fontWeight={600}>
            {userGroup.name}
          </Typography>
        </Stack>

        <Button variant="contained" size="small" onClick={toggleAddMembers}>
          Add members
        </Button>
      </Stack>

      <Box mt={2}>
        <Paper variant="outlined" sx={{p: 2}}>
          <Box mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={(e, tab) => {
              setTab(tab)
            }}>
              {
                _.map(tabs, t => {
                  return (
                    <Tab key={t} sx={{
                      textTransform: "inherit",
                      fontWeight: 600,
                      fontSize: 14
                    }} label={
                      _.capitalize(t)
                    }  />
                  )
                })
              }
            </Tabs>
          </Box>

          {
            tab == 0 &&
              <Members userGroup={userGroup} />
          }


          {
            tab == 1 &&
              <Leaderboard userGroup={userGroup} />
          }
        </Paper>
      </Box>

      <AddMembers
        open={addMembersOpen}
        onClose={toggleAddMembers}
        userGroup={userGroup} />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "userGroups");
export default connect(select, {

})(withRouter(UserGroupDetails));
