import _ from "lodash";
import * as React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Loader from '../../components/Loader';
import DialogCloseButton from '../../components/DialogCloseButton';
import * as leagueActions from '../../actions/leagues';
import * as teamActions from '../../actions/teams';
import withRouter from '../../utils/withRouter';

import GravyLogo from '../../icons/GravyLogo';


function OnboardingMain(props) {
  const { user, fetchLeagues, fetchTeams, history} = props;

  const [loading, setLoading] = React.useState(false);

  const onClose = () => {
    history.replace("../../")
  }

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    return Promise.all([
      fetchLeagues(),
      fetchTeams()
    ])
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={true}>
      <DialogTitle sx={{px: 0}}>
      </DialogTitle>

      <DialogContent sx={{p: 0}}>
        <Container maxWidth="sm">

          {
            loading ?
              <Loader /> :
              <Outlet />
          }
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const select = $$state => _.pick($$state, "user", "leagues", "teams");
export default connect(select, {
  ...teamActions,
  ...leagueActions,
})(withRouter(OnboardingMain));
