import { combineReducers } from "redux";

import alerts from './alerts';
import cards from './cards';
import integrations from './integrations';
import merchantOffers from './merchantOffers';
import notifications from './notifications';
import offers from './offers';
import referrals from './referrals';
import regions from './regions';
import sports from './sports';
import feedItems from './feedItems';
import leagues from './leagues';
import teams from './teams';
import picks from './picks';
import players from './players';
import propositions from './propositions';
import transactions from './transactions';
import user from './user';
import userGroups from './userGroups';

export default combineReducers({
  alerts,
  cards,
  integrations,
  merchantOffers,
  notifications,
  offers,
  referrals,
  regions,
  sports,
  feedItems,
  leagues,
  picks,
  players,
  propositions,
  teams,
  transactions,
  user,
  userGroups
});
