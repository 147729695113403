import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import GravyVideo from '../../components/GravyVideo';
import AuthButton from '../user/AuthButton';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as alertActions from '../../actions/alerts';
import * as userActions from '../../actions/user';

import TopNavBar from '../marketing/TopNavBar';

import Referrals from '../onboarding/Referrals';

function MarketingWaitlist(props) {
  const {
    user,
    history,
    location
  } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [referralsOpen, setReferralsOpen] = React.useState(false)

  const toggleReferrals = () => setReferralsOpen(!referralsOpen)

  return (
    <Box minHeight={"100vh"} pb={12} sx={{background: "#f8f7fa"}}>
      <TopNavBar {...props} />

      <Box pt={{
        xs: 3,
        md: 3
      }} pb={{
        xs: 3,
        md: 3
      }} sx={{background: "#fff", borderBottom: "1px solid rgb(223 223 223 / 87%)"}}>
        <Container maxWidth="sm">
          <Stack  spacing={2}>
            <Box>
              <Typography variant="h1" fontWeight={600} style={{
                fontSize: mobile ? "1.1rem" : "1.25rem"
              }}>
                You've been added to the Gravy waitlist...
              </Typography>
              <Typography variant={mobile ? "body3" : "body2"} color="text.secondary" fontWeight={500}>
                Save on everyday expenses when your teams and players win big.
              </Typography>
            </Box>

            <Box mt={2}>
              <Button variant="contained" fullWidth size="large"
                onClick={toggleReferrals}>
                Skip the line
              </Button>
            </Box>
          </Stack>


        </Container>
      </Box>

      <Box mt={2}>
        <Container maxWidth="sm">
          <Grid container spacing={{xs: 2, sm: 2}}>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>


                <GravyVideo />
              </Stack>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "#fff",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Box p={1}>
          <Stack direction="row" alignItems="center" justifyContent={
            mobile ? "start" : "center"
          }>
            <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
              style={{
                height: 40,
                position: "relative",
                top: 3
              }} />
            <Link variant="body3" fontWeight={600} color="primary" component={RouterLink} to="/about" target="_blank">
              Built by the team behind Plaid ($13.5B)
            </Link>
          </Stack>
        </Box>
      </AppBar>

      <Referrals
        open={referralsOpen}
        onClose={toggleReferrals}
        {...props} />
    </Box>
  )
}

const select = $$state => _.pick($$state, "user", "referrals");
export default connect(select, {
  ...alertActions,
  ...userActions
})(withRouter(MarketingWaitlist));
