import _ from "lodash";
import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import request from '../../utils/request';

import AutocompleteMerchants from '../../components/AutocompleteMerchants';

export default function MerchantsSearch(props) {
  const navigate = useNavigate();

  return (
    <Box pt={2}>
      <Container maxWidth="sm">
        <AutocompleteMerchants onChange={(e, value) => {
          window.location.href = `/discounts/${value.slug}`
        }} />
      </Container>
    </Box>
  );
}
