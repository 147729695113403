import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import categoryBackgrounds from '../../utils/categoryBackgrounds';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as cardActions from '../../actions/cards';
import withRouter from '../../utils/withRouter';

import {usePostHog} from "posthog-js/react";

function MerchantOffersList(props) {
  const {
    merchantOffers,
    fetchMerchantOffers,
    cards,
    fetchCards,
    params,
    history
  } = props;

  const [loading, setLoading] = React.useState(true)

  const categories = [
    "coffee",
    "restaurant",
    "food_delivery",
    "bar",
    "ride_share",
    "groceries",
    "dessert",
    "clothing",
    "ticketing"
  ];

  const onClose = () => history.replace("../");

  const prettyCategoryName = category => {
    const lastLetter = category[category.length - 1];

    switch(lastLetter) {
      case "s":
      case "a":
      case "e":
      case "i":
      case "o":
      case "u":
      case "g":
        return category;
      case "y":
        return `${_.trimEnd(category, lastLetter)}ies`
      default:
        return `${category}s`
    }
  }

  React.useEffect(() => {
    Promise.all([
      merchantOffers.length == 0 ? fetchMerchantOffers() : Promise.resolve(),
      cards.length == 0 ? fetchCards() : Promise.resolve(),
    ]).finally(() => setLoading(false))
  })

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const showMerchants = gravyCard == null ||
    plaidCard != null;

  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="md">
        {
          loading ?
            <Loader /> :
            <Box>
              <Box>
                <Typography variant="h2" fontWeight={700}>
                  Can you pour Gravy on it?
                </Typography>

                {
                  showMerchants ?
                    <Typography variant="body1" lineHeight={1.3}>
                      Shop at ANY of these businesses today with your Gravy
                      Card to earn a Pick.
                    </Typography> :
                    <Typography variant="body1" lineHeight={1.3}>
                      Shop anywhere Visa is accepted to earn Picks.
                    </Typography>
                }
              </Box>

              {
                showMerchants ?
                  <Box mt={2}>
                    <Alert color="error">
                      <Link component={RouterLink} to="./issues" variant="body2" fontWeight={600} color="error">
                        I shopped but haven't received a Pick?
                      </Link>
                    </Alert>
                  </Box> :
                  <Box mt={2}>
                    <Alert color="primary">
                      <Link component={RouterLink} to="/app/account/cards" variant="body2" fontWeight={600}>
                        How can I find my Gravy Card or add one?
                      </Link>
                    </Alert>
                  </Box>
              }

              <Box mt={2}>
                <Grid container spacing={1}>
                  {
                    showMerchants ?
                      _.map(merchantOffers, (mo, i) => {
                        const logo = _.get(mo, ["merchant", "logo", "secure_url"]);
                        return (
                          <Grid item xs={6} md={4} key={i}>
                            <Paper variant="outlined" p={2} sx={{
                              height: {
                                xs: 130,
                                md: 200
                              },
                              display: "flex",
                            }}>
                              <Stack alignItems="center" justifyContent="center" sx={{flexGrow: 1}}>
                                <img src={logo} style={{
                                  height: 80,
                                  borderRadius: 5
                                }} />
                              </Stack>
                            </Paper>
                          </Grid>
                        )
                      }) :
                      _.map(categories, (category, i) => {
                        const background = categoryBackgrounds[category]

                        return (
                          <Grid item xs={6} sm={4} key={i}>
                            <Paper p={2} sx={{
                              height: 230,
                              display: "flex",
                              backgroundSize: "cover",
                              backgroundImage: `url(${background})`,
                              position: "relative",
                              cursor: "pointer"
                            }}>
                                  <Box sx={{
                                    background: "rgba(0,0,0,0.65)",
                                    p: 1,
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                  }}>
                                    <Typography variant="body4" fontWeight={700} lineHeight={1.2} color="#fff" textTransform="uppercase">
                                      Save on {
                                        _.lowerCase(prettyCategoryName(category))
                                      }
                                    </Typography>
                                  </Box>
                            </Paper>
                          </Grid>
                        )
                      })
                  }
                </Grid>
              </Box>
            </Box>
        }

      </Container>

      <Outlet />
    </Box>

  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "cards"]);
export default connect(select, {
  ...merchantOffersActions,
  ...cardActions,
})(withRouter(MerchantOffersList));
