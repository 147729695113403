import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

function LoginForm(props) {
  const {
    phone,
    login,
    resendActivationCode,
    onAuthentication,
    history
  } = props;

  const [resending, setResending] = React.useState(null);

  const validationSchema = yup.object({
    phone: yup
      .string()
      .required(),
    code: yup
      .string()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      phone,
      code: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await login(values);
        if (results.errors) {
          alert(results.errors.message)
        } else if (!!onAuthentication) {
          onAuthentication()
        } else {
          history.push(`/app`)
        }
      } catch(err) {
        alert("There was an error")
      }
    }
  });

  const resend = async () => {
    try {
      setResending(true);

      const results = await resendActivationCode(phone);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        alert("Code resent")
      }
    } catch(err) {
      alert("There was an error")
    } finally {
      setResending(false)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          autoFocus
          fullWidth
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          label="Verification code sent to your phone"
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Verify
        </LoadingButton>

        <LoadingButton
          variant="outlined"
          onClick={resend}
          saving={resending}
          fullWidth>
          Resend Code
        </LoadingButton>
      </Stack>
    </form>
  )
}

function PhoneForm({resendActivationCode, onSuccess}) {
  const validationSchema = yup.object({
    phone: yup
      .number()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema,
    onSubmit: async ({phone}) => {
      try {
        const results = await resendActivationCode(phone);
        if (results.errors) {
          alert(results.errors.message)
        } else {
          onSuccess(phone)
        }
      } catch(err) {
        alert("There was an error")
      } finally {
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          autoFocus
          type="number"
          fullWidth
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          label="Phone number"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Log in
        </LoadingButton>

      </Stack>
    </form>
  )
}

export default function Login(props) {
  const [phone, setPhone] = React.useState("");

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Login
      </Typography>

      <Typography variant="body3" color="text.secondary">
        Don't have an account? Please <Link
          underline={"always"} href="#" onClick={e => {
            e.preventDefault();
            props.setScreen("signup")
          }}>sign up</Link>.
      </Typography>

      <Box mt={2}>
        {
          !phone ?
            <PhoneForm onSuccess={phone => {
              setPhone(phone);
            }} {...props} /> :
            <LoginForm phone={phone} {...props} />
        }
      </Box>
    </Box>
  );
}
