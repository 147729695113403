import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '../../components/TextField';
import Link from '../../components/Link';

import * as analytics from '../../utils/analytics';

function ActivateForm(props) {
  const { user, activateUser, onAuthentication, resendActivationCode, onClose} = props;
  const navigate = useNavigate();

  const [resending, setResending] = React.useState(null);

  const resend = async () => {
    try {
      setResending(true);

      const results = await resendActivationCode();
      if (results.errors) {
        alert(results.errors.message)
      } else {
        alert("Code resent")
      }
    } catch(err) {
      alert("There was an error")
    } finally {
      setResending(false)
    }
  }

  const validationSchema = yup.object({
    code: yup
      .number()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: async ({code}) => {
      try {
        const results = await activateUser(user, code);
        if (results.errors) {
          alert(results.errors.message)
        } else {
          if (onAuthentication != null) {
            onAuthentication();
          } else if (!!user.email) {
            navigate(`/app`)
          } else {
            navigate(`/app/onboarding`)
          }
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          label="Verification code sent to your phone"
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Verify
        </LoadingButton>

        <LoadingButton
          variant="outlined"
          onClick={resend}
          saving={resending}
          fullWidth>
          Resend Code
        </LoadingButton>
      </Stack>
    </form>
  )
}

function SignupForm({createUser, setStep}) {
  const [errors, setErrors] = React.useState(null);

  const validationSchema = yup.object({
    phone: yup
      .number()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await createUser({
          ...values,
        })

        if (results.errors) {
          alert(results.errors.message)
        } else  {
          analytics.logEvent("Signup");
          setStep("verify")
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          type="number"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          label="Phone Number"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Sign up
        </LoadingButton>

      </Stack>
    </form>
  )
}

export default function Signup(props) {
  const [step, setStep] = React.useState("signup");

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Signup
      </Typography>

      <Typography variant="body3" color="text.secondary">
        Already have an existing account? <Link
        underline={"always"} href="#" onClick={e => {
          e.preventDefault();
          props.setScreen("login")
        }}>Log in</Link>.
      </Typography>

      <Box mt={2}>
        {
          step == "signup" ?
            <SignupForm  {...props} setStep={setStep} /> :
            <ActivateForm {...props} />
        }
      </Box>
    </Box>
  );
}
