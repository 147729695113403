import _ from "lodash";
import * as React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import * as environment from '../../environment';

export default function UserGroupAddMembers(props) {
  const { open, onClose, userGroup } = props;

  const inviteUrl = `${environment.httpsHost}/groups/${userGroup.token}`

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="body1" fontWeight={600}>
            Invite friends and family to Gravy
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            Compete to see who best sees the future of sports, culture, and news.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>

      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <CopyToClipboard
          text={inviteUrl}
          onCopy={() => {
            alert("copied")
            onClose()
          }}>
          <Button variant="contained" color="primary">
            Copy Invite Link
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>

  );
}
