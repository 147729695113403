import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import DialogCloseButton from '../../components/DialogCloseButton';
import DialogBackButton from '../../components/DialogBackButton';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

function OnboardingActivation(props) {
  const { user, activateUser, onAuthentication, resendActivationCode, history} = props;

  const onClose = () => history.push("../");

  const [resending, setResending] = React.useState(null);

  const resend = async () => {
    try {
      setResending(true);

      const results = await resendActivationCode();
      if (results.errors) {
        alert(results.errors.message)
      } else {
        alert("Code resent")
      }
    } catch(err) {
      alert("There was an error")
    } finally {
      setResending(false)
    }
  }

  const validationSchema = yup.object({
    code: yup
      .number()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: async ({code}) => {
      try {
        const results = await activateUser(user, code);
        if (results.errors) {
          alert(results.errors.message)
        } else {
          history.push(`../identity`)
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      sx={{zIndex: 10000000}}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />

          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={600}>
          Verify your phone number
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              label="Enter code sent to your phone"
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code} />

            <LoadingButton loading={formik.isSubmitting}
              variant="contained"
              fullWidth
              type="submit">
              Verify
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              onClick={resend}
              saving={resending}
              fullWidth>
              Resend Code
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, userActions)(withRouter(OnboardingActivation));
