import _ from 'lodash';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { connect } from 'react-redux';

import * as analytics from './analytics';
import * as account from './account';
import * as balance from './balance';
import * as cards from './cards';
import * as dashboard from './dashboard';
import * as feedItems from './feedItems';
import * as leaderboard from './leaderboard';
import * as onboarding from './onboarding';
import * as help from './help';
import * as marketing from './marketing';
import * as merchants from './merchants';
import * as picks from './picks';
import * as players from './players';
import * as sportsEvent from './players';
import * as notifications from './notifications';
import * as offers from './offers';
import * as payouts from './payouts';
import * as profiles from './profiles';
import * as referrals from './referrals';
import * as transactions from './transactions';
import * as user from './user';
import * as userGroups from './userGroups';

import * as userActions from '../actions/user';

function Routing(props) {
  const [rendered, setRendered] = React.useState(false);

  let pollingId;

  React.useEffect(() => {
    const user = window.localStorage.getItem("user");

    if (!!user) {
      const parsedUser = JSON.parse(user);

      props.updateUser(parsedUser);

      pollUser(parsedUser)
    }

    setRendered(true);

    return () => clearInterval(pollingId);
  }, [])

  const pollUser = (user) => {
    const pollingId = setInterval(() => {
      props.pingUser(user)
    }, 1000 * 60 * 60);

    return pollingId;
  };

  if (!rendered) return <React.Fragment />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<analytics.Main />}>

          <Route path="context" element={<user.Contextify />} />

          <Route path="l">
            <Route path=":offerId" element={<offers.Landing />} />
          </Route>

          <Route path="p">
            <Route path=":offerId" element={<offers.Details />}>
            </Route>
          </Route>

          <Route path="app" element={<dashboard.Main />}>
            <Route path="account" element={<account.Main />}>
              <Route path="" element={<account.Details />}>
                <Route path="plaid" element={<account.Plaid />} />
              </Route>

              <Route path="interests" element={<account.Interests />} />

              <Route path="referrals" element={<account.Referrals />}>
                <Route path="" element={<referrals.List />}>
                  <Route path="new" element={<referrals.New />} />
                </Route>
              </Route>

              <Route path="balance" element={<balance.Main />}>
                <Route path="payouts/new" element={<payouts.New />} />
              </Route>

              <Route path="groups" element={<userGroups.Main />}>
                <Route path="" element={<userGroups.List />}>
                  <Route path="new" element={<userGroups.New />} />
                </Route>

                <Route path=":userGroupId" element={<userGroups.Details />} />
              </Route>

              <Route path="cards" element={<cards.Main />}>
                <Route path="" element={<cards.List />}>
                  <Route path="new" element={<cards.New />}>
                    <Route path="plaid" element={<cards.Plaid />} />
                  </Route>

                  <Route path=":cardId" element={<cards.Details />} />
                </Route>
              </Route>

              <Route path="favorites" element={<account.Favorites />} />


              <Route path="fantasy" element={<account.Fantasy />} />

            </Route>


            <Route path="profiles">
              <Route path=":username" element={<profiles.Main />} />
            </Route>

            <Route path="merchants" element={<merchants.List />}>
              <Route path="issues" element={<merchants.Issues />} />
            </Route>

            <Route path="leaderboard" element={<leaderboard.Main />} />

            <Route path="offers" element={<offers.Main />}>
              <Route path="" element={<offers.List />}>
                <Route path=":offerId" element={<offers.Details />}>
                  <Route path="instructions" element={<offers.Instructions />} />
                </Route>
              </Route>
            </Route>

            <Route path="transactions" element={<transactions.Main />}>
              <Route path="" element={<transactions.List />} />
            </Route>

            <Route path="" element={<dashboard.Home />}>
              <Route path="picks">
                <Route index element={<picks.Main />} />
              </Route>

              <Route path="feedItems">
                <Route path=":feedItemId" element={<feedItems.Details />}>
                  <Route path=":offerId" element={<offers.Details />}>
                  </Route>
                </Route>
              </Route>
            </Route>

          </Route>

          <Route path="" element={<marketing.Main />}>
            <Route path="" element={<marketing.Home />} />

            <Route path="how-it-works" element={<marketing.How />} />
            <Route path="about" element={<marketing.About />} />
            <Route path="pricing" element={<marketing.Pricing />} />
            <Route path="privacy" element={<marketing.Privacy />} />
            <Route path="terms" element={<marketing.Terms />} />

            <Route path="faq" element={<help.Main />}>
              <Route index element={<help.Home />} />

              <Route path=":category">
                <Route index element={<help.Category />} />
                <Route path=":page" element={<help.Details />} />
              </Route>
            </Route>
          </Route>

          <Route path="waitlist" element={<marketing.Waitlist />} />

          <Route path="cards">
            <Route path=":cardId" element={<cards.Marketing />} />
          </Route>

          <Route path="groups">
            <Route path=":userGroupId" element={<userGroups.Marketing />} />
          </Route>

          <Route path="rewards">
            <Route path=":cardPromiseId" element={<cards.Promise />} />
          </Route>

          <Route path="discounts" element={<merchants.Main />}>
            <Route path="" element={<merchants.Search />} />
            <Route path=":merchantId" element={<merchants.Details />} />
          </Route>

          <Route path="players" element={<players.Main />}>
            <Route path=":playerId" element={<players.Details />} />
          </Route>

          <Route path="onboarding" element={<onboarding.Main />}>
            <Route path="" element={<onboarding.Signup />}>
              <Route path="activation" element={<onboarding.Activation />} />
            </Route>

            <Route path="identity" element={<onboarding.Identity />}>
              <Route path="football" element={<onboarding.Football />} />

            </Route>

            <Route path="interests" element={<onboarding.Interests />} />
            <Route path="leagues" element={<onboarding.Leagues />} />
            <Route path="teams" element={<onboarding.Teams />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions,
})(Routing);
