import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import GravyVideo from '../../components/GravyVideo';
import AuthButton from '../user/AuthButton';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import OfferDetail from '../offers/Details';

export default function MerchantDetails(props) {
  const {
    params,
    history
  } = useOutletContext();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = React.useState(true);
  const [merchant, setMerchant] = React.useState(null)
  const [offer, setOffer] = React.useState({})
  const [offerOpen, setOfferOpen] = React.useState(false);

  const toggleOfferOpen = () => setOffer(!offerOpen)

  React.useEffect(() => {
    if (!!params.merchantId) fetchMerchant(params.merchantId).finally(() => setLoading(false))
  }, [params.merchantId])

  React.useEffect(() => {
    if (!!merchant) {
      window.document.title = `Gravy | Save big at ${merchant.name} when your favorite players and teams win big`;
    }

  }, [merchant]);

  const fetchMerchant = async merchantId => {
    const { data } = await request.get(`merchants/${merchantId}`)

    if (!!data.id) {
      setMerchant({
        id: data.id,
        ...data.attributes
      })
    }
  };

  const onboard = async () => {
    history.push("/onboarding")
  }

  const claimable = _.includes(["pending"], offer.status)

  const logo = _.get(merchant, ["logo", "secure_url"], "")

  const proposition = _.get(offer, ["proposition"], {});

  if (loading) return <Box />

  return (
    <Box minHeight={"100vh"} pb={12} sx={{background: "#f8f7fa"}}>
      <Box pt={{
        xs: 3,
        md: 4
      }} pb={{
        xs: 3,
        md: 4
      }} sx={{background: "#fff"}}>
        <Container maxWidth="sm">
          <Stack direction={{
            xs: "row",
            md: "row"
          }}
          spacing={1}

          alignItems={{
            xs: "initial",
            md: "center"
          }}>
            {
              !!logo && <Box display={{
                xs: "block",
              }}>
                <img style={{
                  height: 40,
                  borderRadius: 8
                }} src={logo} />
              </Box>
            }

            <Box>
              <Typography variant="h1" fontWeight={600} style={{
                fontSize: mobile ? "1.1rem" : "1.25rem"
              }}>
                {merchant.name} discounts for sports fans
              </Typography>
              <Typography variant={mobile ? "body3" : "body3"} color="text.secondary" fontWeight={500}>
                Save big on everyday expenses when your teams and players win big.
              </Typography>
            </Box>
          </Stack>

          {
            false &&
              <Alert color="primary" sx={{mt: 2}}>
                <Link href="#" variant={mobile ? "body3" : "body2"} fontWeight={600} color="primary" onClick={e => {
                  e.preventDefault();
                }}>
                  See how the Gravy secret sauce is made...
                </Link>
              </Alert>
          }

        </Container>
      </Box>

      <Box mt={3}>
        <Container maxWidth="sm">
          <Grid container spacing={{xs: 2, sm: 2}}>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>
                {
                  _.isEmpty(offer) ?
                    <Paper sx={{p: 3}} variant="outlined">
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="body2" fontWeight={600}>
                            Sign up to receive your first {merchant.name} offer.
                          </Typography>
                          <Typography variant="body3" color="text.secondary" fontWeight={600}>
                            Plus offers from other major brands.
                          </Typography>
                        </Box>

                        <Button
                          onClick={onboard}
                          size="large"
                          variant="contained"
                          fullWidth>
                          Save at {merchant.name}
                        </Button>
                      </Stack>
                    </Paper> :
                    <Paper sx={{p: 3}} variant="outlined">
                      <Stack spacing={2}>

                        <Box>
                          <Typography variant="body2" fontWeight={600}>
                            { currencyFormatter(offer.prize_amount) } off { merchant.name }
                          </Typography>
                          <Typography variant="body3" color="text.secondary" fontWeight={600}>
                            If {proposition.winDescription}
                          </Typography>
                        </Box>

                        <Button
                          disabled={!claimable}
                          size="large"
                          onClick={toggleOfferOpen}
                          variant="contained"
                          fullWidth={mobile}>
                          View Offer
                        </Button>

                        <Typography variant="body4" textAlign="center" textTransform="uppercase" fontWeight={600}>
                          {
                            time.getTimeTill(
                              proposition.eligibilityEndsAt
                            )
                          }
                        </Typography>
                      </Stack>
                    </Paper>
                }

                <GravyVideo />
              </Stack>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <OfferDetail
        offer={offerOpen ? offer : {}}
        onClose={toggleOfferOpen}
        {...props} />

      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "#fff",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Box p={1}>
          <Stack direction="row" alignItems="center" justifyContent={
            mobile ? "start" : "center"
          }>
            <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
              style={{
                height: 40,
                position: "relative",
                top: 3
              }} />
            <Link variant="body3" fontWeight={600} color="primary" component={RouterLink} to="/about" target="_blank">
              Built by the team behind Plaid ($13.5B)
            </Link>
          </Stack>
        </Box>
      </AppBar>

    </Box>
  )
}
