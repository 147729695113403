import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as offerActions from '../../actions/offers';

function BalanceMain(props) {
  const {
    transactions,
    offers,
    fetchOffers,
    history
  } = props;

  const availableBalance = _.chain(transactions)
    .first()
    .get("balance_cents", 0)
    .value() / 100.0;

  const pendingBalance = availableBalance + _.chain(offers)
    .filter(o => o.status == "won" && o.prize.status != "completed")
    .map(o => o.prize.amount_cents)
    .sum()
    .value() / 100.0

  React.useEffect(() => {
    if (offers.length == 0) fetchOffers()
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Paper variant="outlined" sx={{
          pt: {
            xs: 4,
            sm: 8
          },
          pb: {
            xs: 4,
            sm: 8
          },
          px: 4,
          minHeight: {
            xs: 100,
            sm: 250
          }
        }}>
          <Stack spacing={2} justifyContent="center">
            <Box>
              <Typography variant="body3" fontWeight={500} textAlign="center" gutterBottom>
                Available Balance
              </Typography>

              <Typography variant="h4" fontWeight={600} textAlign="center">
                {
                  currencyFormatter(availableBalance, 2)
                }
              </Typography>
            </Box>

            <Button
              fullWidth
              variant="contained"
              disabled={availableBalance == 0}
              color="primary" onClick={() => {
              history.push("./payouts/new")
            }}>
              Withdraw Funds
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper variant="outlined" sx={{
          pt: {
            xs: 4,
            sm: 8
          },
          pb: {
            xs: 4,
            sm: 8
          },
          px: 4,
          minHeight: {
            xs: 100,
            sm: 250
          }
        }}>
          <Stack spacing={2} justifyContent="center">
            <Box>
              <Typography variant="body3" fontWeight={500} textAlign="center" gutterBottom>
                Pending Balance
              </Typography>

              <Typography variant="h4" fontWeight={600} textAlign="center">
                {
                  currencyFormatter(pendingBalance, 2)
                }
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}

const select = $$state => _.pick($$state, "transactions", "offers");
export default connect(select, {
  ...offerActions
})(withRouter(BalanceMain));

