import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as alertActions from '../../actions/alerts';

function ReferralsNew(props) {
  const { user, referrals, createAlert, history } = props;

  const url = `https://${window.location.host}?ref=${user.referral_code}`;

  const onClose = () => history.push("../")

  return (
    <FullScreenDialog
      open={true}
      fullWidth
      maxWidth="xs">
      <DialogTitle>

      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            Share this link with friends
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            For each friend who connects their card to Gravy,
            you'll get an extra Pick.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <CopyToClipboard
          text={url}
          onCopy={() => {
            createAlert({title: "URL copied to clipboard"})
            onClose()
          }}>
          <Button variant="contained" color="primary">
            Copy Link
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </FullScreenDialog>

  );
}

const select = $$state => _.pick($$state, "user", "referrals");
export default connect(select, {
  ...alertActions
})(withRouter(ReferralsNew));
