import _ from "lodash";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const getScreen = pathname => {
  switch (true) {
    case /offers/.test(pathname):
      return "offers";
    case /leaderboard/.test(pathname):
      return "leaderboard";
    case /merchants/.test(pathname):
      return "merchants";
    case /account/.test(pathname):
      return "account";
    default:
      return "home";
  }
}

function BottomNavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const screen = getScreen(pathname);

  return (
    <AppBar position="fixed" color="primary" sx={{
      top: 'auto',
      bottom: 0,
      background: "#fff",
      color: "text.primary",
      boxShadow: "none",
      borderTop: "1px solid #cccccc",
    }}>
      <Container maxWidth="sm">
        <Box py={1}>
          <Stack direction="row" sx={{
            justifyContent: "space-between",
            width: "100%"
          }}>

            <Box>
              <IconButton component={RouterLink} to="/app">
                <HomeIcon  sx={{
                  color: screen == "home" ? "#343a40" : "rgba(0, 0, 0, 0.25)"
                }} />
              </IconButton>
            </Box>

            <Box>
              <IconButton component={RouterLink} to="/app/merchants">
                <AddShoppingCartIcon sx={{
                  color: screen == "merchants" ? "#343a40" : "rgba(0, 0, 0, 0.25)"
                }} />
              </IconButton>
            </Box>


            <Box>
              <IconButton component={RouterLink} to="/app/leaderboard">
                <EmojiEventsIcon sx={{
                  color: screen == "leaderboard" ? "#343a40" : "rgba(0, 0, 0, 0.25)"
                }} />
              </IconButton>
            </Box>

            <Box>
              <IconButton component={RouterLink} to="/app/offers">
                <ReceiptIcon sx={{
                  color: screen == "offers" ? "#343a40" : "rgba(0, 0, 0, 0.25)"
                }} />
              </IconButton>
            </Box>


            <Box sx={{position: "relative", cursor: "pointer"}} component={RouterLink} to="/app/account">
              <IconButton>
                <AccountCircleIcon  sx={{
                  color: screen == "account" ? "#343a40" : "rgba(0, 0, 0, 0.25)"
                }} />
              </IconButton>
            </Box>

          </Stack>
        </Box>
      </Container>
    </AppBar>
  );
};

export default BottomNavBar;
