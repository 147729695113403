import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import Loader from '../../components/Loader';
import * as feedItemsActions from '../../actions/feedItems';
import * as propositionActions from '../../actions/propositions';
import * as offersActions from '../../actions/offers';
import withRouter from '../../utils/withRouter';

function FeedItemsMain(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    offers,
    fetchOffers,
    propositions,
    fetchPropositions
  } = props;


  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="md">
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <ConfirmationNumberIcon style={{
              fontSize: 30
            }} />
            <Typography variant="h3" fontWeight={700} textTransform="uppercase">
              What'll happen
            </Typography>
          </Stack>
          <Typography variant="h1" fontWeight={700} textTransform="uppercase"  fontStyle="italic">
            In sports, news, and culture?
          </Typography>
          <Typography variant="body1" lineHeight={1.3} mt={1}>
            Can you see the future? You'll save big on daily expenses if you do.
          </Typography>
        </Box>

        <Box mt={2}>
          {
            feedItems.length == 0 ?
              <Loader /> :
              <Outlet />
          }
        </Box>
      </Container>
    </Box>
  );
}


const select = $$state => _.pick($$state, "user", "feedItems", "propositions", "offers");
export default connect(select, {
  ...feedItemsActions,
  ...propositionActions,
  ...offersActions
})(withRouter(FeedItemsMain));
