import _ from "lodash";
import moment from "moment";
import { useSwipeable } from "react-swipeable";
import * as React from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Outlet,
  useOutletContext,
  useLocation,
  useNavigate,
  Link as RouterLink,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpIcon from '@mui/icons-material/Help';

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import withRouter from "../../utils/withRouter";
import * as time from "../../utils/time";
import * as dates from "../../utils/dates";
import * as offersActions from "../../actions/offers";
import currencyFormatter from "../../utils/currencyFormatter";
import FullScreenDialog from "../../components/FullScreenDialog";

import Instructions from "./Instructions";
import Faqs from "./Faqs";
import AuthButton from "../user/AuthButton";
import { usePostHog } from "posthog-js/react";

import categoryBackgrounds from '../../utils/categoryBackgrounds';

import Lost from './Lost';
import Won from './Won';

function OfferDetails(props) {
  const {
    offers,
    user,
    deleteOffer,
    merchantOffers,
    params,
    history,
    location,
  } = props;

  const offerId = params.offerId;

  const onClose = () => history.replace("../");

  const [saving, setSaving] = React.useState(false);
  const posthog = usePostHog();

  const offer = _.find(offers, (o) => o.id == offerId) || {};

  if (_.isEmpty(offer)) return <Box />;

  const { proposition, prize, status, feed_item, requirement_title } = offer;

  const timeLeft = time.getTimeTill(proposition.eligibilityEndsAt);

  const prizeStatus = _.get(prize, ["status"], "pending");

  const categories = ["bar", "food_delivery", "restaurant", "groceries"];

  const destroyOffer = async () => {
    try {
      setSaving(true);
      const results = await deleteOffer(offer.id);
      if (results.errors) {
        alert(results.errors.message);
      } else {
        posthog.capture("offer_declined", {
          offer_id: offer.id,
        });

        history.push(`../`)
      }
    } catch (err) {
      alert(err.message);
    } finally {
      setSaving(false);
    }
  };

  const prettyCategoryName = category => {
    const lastLetter = category[category.length - 1];

    switch(lastLetter) {
      case "s":
      case "a":
      case "e":
      case "i":
      case "o":
      case "u":
      case "g":
        return category;
      case "y":
        return `${_.trimEnd(category, lastLetter)}ies`
      default:
        return `${category}s`
    }
  }

  console.log(offer);

  return (
    <FullScreenDialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{
        px: 2,
        pb: 4,
        pt: 4,
        borderBottom: "1px solid #dddcdc"
      }}>

        <Box>
          <Typography variant="body1" fontWeight={500} lineHeight={1.3} mb={0.5}>
            {feed_item.title}
          </Typography>

          <Typography
            variant="h3"
            fontWeight={600}
            lineHeight={1.3}
            mb={0.5}>
            {prize.description} cash back if {proposition.win_description}.
          </Typography>

        </Box>
      </DialogTitle>
      <DialogContent sx={{px: 2}}>
        <Box mt={4} mb={6}>

          {
            status == "verified" &&
              <Alert variant="filled" color="primary">
                <Typography variant="body2" fontWeight={600}>
                  You'll get notified if you win or lose!
                </Typography>
              </Alert>
          }

          {
            status == "won" &&
              <Won prizeStatus={prizeStatus} />
          }


          {
            status == "lost" &&
              <Lost />
          }
        </Box>

        <Box mt={4}>
          <Typography variant="body3"  fontWeight={600} textTransform="uppercase" mb={1}>
            Details
          </Typography>
          <Stack spacing={1}>

            {
              offer.status == "activated" &&
                <Stack direction="row" spacing={2} alignItems="center">
                  <AccessTimeIcon sx={{fontSize: 20}} />

                  <Typography variant="body3"  fontWeight={500}>
                    Offer expires {dates.tzAwareDateTime(offer.expires_at, "MM/DD hh:mm A")}
                  </Typography>
                </Stack>

            }

            <Stack direction="row" spacing={2} alignItems="center">
              <CalendarMonthIcon sx={{fontSize: 20}} />

              <Typography variant="body3"  fontWeight={500}>
                Event ends {dates.tzAwareDateTime(offer.proposition.eligibility_ends_at, "MM/DD hh:mm A")}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Outlet />
      </DialogContent>
      <DialogActions sx={{
        py: 2,
        pl: 1,
        pr: 2,
        justifyContent: "space-between"
      }}>

          <Button onClick={onClose} startIcon={<ArrowBackIosNewIcon />} sx={{color: "black", fontWeight: 600}}>
            Back
          </Button>

          {
            offer.status == "activated" &&
              <LoadingButton loading={saving} variant="contained" color="error" onClick={destroyOffer}>
                Remove
              </LoadingButton>
          }

      </DialogActions>
    </FullScreenDialog>
  );
}

const select = ($$state) =>
  _.pick($$state, [
    "user",
    "offers",
    "merchantOffers"
  ]);
export default connect(select, {
  ...offersActions,
})(withRouter(OfferDetails));
