import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';

import PlaidLogo from '../../icons/PlaidLogo';

import FeedItemCard from '../feedItems/Card';
import Card from '../cards/Card';

import Wallet from '../cards/Wallet';

function CardAddToWallet(props) {
  const {
    pan,
    cvc,
    expiration_month,
    expiration_year,
  } = props;

  return (
    <Box>
      <Box mb={3}>
        <Alert color="primary">
          <Typography variant="body2" lineHeight={1.3} fontWeight={600}>
            Copy and paste the number below into you Apple or Google wallet.
          </Typography>
        </Alert>
      </Box>

        <Box my={4}>

          <Stack direction="row" alignItems="center" justifyContent="space-between">

            <Box>
              <Typography variant="body3" fontWeight={600}>Expires</Typography>
              <Typography variant="body3">{
                `${expiration_month}/${expiration_year}`
              }</Typography>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={3}>

              <Box>
                  <CopyToClipboard
                    text={pan}
                    onCopy={e => {
                      alert("Copied")
                    }}>
                    <Box>
                      <Typography variant="body3" fontWeight={600}>Number</Typography>
                      <Stack direction="row" spacing={1} alignItems="center">

                        <ContentCopyIcon sx={{
                          fontSize: 18,
                        }} />

                        <Typography variant="body2">{
                          pan
                        }</Typography>
                      </Stack>
                    </Box>
                  </CopyToClipboard>
              </Box>

              <Box>
                <Typography variant="body3" fontWeight={600}>CVC</Typography>

                <Typography variant="body2">{cvc}</Typography>
              </Box>

            </Stack>

          </Stack>

        </Box>
    </Box>
  )
}

export default function InstructionsShop(props) {
  const {
    user,
    merchantOffers,
    card,
    next,
  } = props;

  const [walletOpen, setWalletOpen] = React.useState(false);

  return (
    <Box>
      <Box>
        {
          !!card  ?
            <Box>
              <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
                Shop with your Card, Earn Picks
              </Typography>
              <Typography variant="body2">
                You can shop anywhere Visa is accepted.
              </Typography>
            </Box> :
            <Box>
              <Typography variant="h4" fontWeight={700} lineHeight={1.2}>
                Shop with your card to earn Picks
              </Typography>
              <Typography variant="body1">
                At Gravy featured merchants like...
              </Typography>

            </Box>
        }

      </Box>

      <Box mt={3}>

        {
          !!card ?
            <Box>
              {
                walletOpen ?
                  <CardAddToWallet {...card} {...props} /> :
                  <Card {...props} {...card}  skipClick={true}  />
              }
            </Box> :
            <Grid container spacing={1}>
              {
                  _.chain(merchantOffers)
                    .take(6)
                    .map((mo, i) => {
                      const logo = _.get(mo, ["merchant", "logo", "secure_url"]);
                      return (
                        <Grid item xs={6} md={6} key={i}>
                          <Paper variant="outlined" p={2} sx={{
                            height: {
                              xs: 130,
                              md: 200
                            },
                            display: "flex",
                          }}>
                            <Stack alignItems="center" justifyContent="center" sx={{flexGrow: 1}}>
                              <img src={logo} style={{
                                height: 80,
                                borderRadius: 5
                              }} />
                            </Stack>
                          </Paper>
                        </Grid>
                      )
                    })
                    .value()
              }
            </Grid>
        }
      </Box>

      {
        !!card &&
          <Box pt={4}>
            <Stack direction="row" justifyContent="end" spacing={1}>
              {
                !walletOpen &&
                  <Button size="large" variant="contained" onClick={() => setWalletOpen(true)} sx={{
                    background: "black"
                  }}>
                    Add to Mobile Wallet
                  </Button>
              }

              <Button size="large" variant="contained" onClick={next}>
                Added
              </Button>
            </Stack>
          </Box>
      }

    </Box>
  );
}
