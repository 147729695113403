import _ from "lodash";
import qs from 'qs';
import * as React from 'react';
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useFormik, Field } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import Alert from '../../components/Alert';
import PhoneInput from '../../components/PhoneInput';
import Link from '../../components/Link';
import TextField from '../../components/TextField';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';

import Footer from './Footer';
import Preview from './Preview';

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .required(),
  last_name: yup
    .string()
    .trim()
    .required(),
  email: yup
    .string()
    .required(),
})

function OnboardingIdentity(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user, saveUser, history, location } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const onClose = () => history.replace("../");


  const formik = useFormik({
    initialValues: {
      first_name: _.chain(user).get("name", "").split(" ").first().value() || "",
      last_name: _.chain(user).get("name", "").split(" ").last().value() || "",
      email: _.get(user, "email", ""),
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const results = await saveUser(user, {
          ...values,
          name: `${values.first_name} ${values.last_name}`
        })

        if (results.errors) {
          alert(results.errors.message)
        } else {
          history.push("./football")
        }
      } catch(err) {
        debugger;
        alert("There was an error")
      } finally {
      }
    },
  });

  return (
    <Box pb={12}>
      <Box mb={4}>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={3}>
            <Typography variant="h3" fontWeight={600}>
              What's your name and email?
            </Typography>

            <Typography variant="body1" color="text.secondary" fontWeight={500}>
              This is used to secure your account.
            </Typography>
          </Box>

          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <TextField
                autoFocus
                fullWidth
                label="First name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name} />

              <TextField
                fullWidth
                label="Last name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name} />
            </Stack>

            <TextField
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email} />
          </Stack>

          <Footer
            loading={formik.isSubmitting}
            progress={100} />
        </form>
      </Box>

      <Divider />

      <Box mt={4}>
        <Preview />
      </Box>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions,
})(withRouter(OnboardingIdentity));
