import _ from "lodash";
import * as React from 'react';
import Promise from 'bluebird';
import { Link as RouterLink, useNavigate, useParams, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import request from '../../utils/request';
import * as dates from '../../utils/dates';
import currencyFormatter from '../../utils/currencyFormatter';
import FullScreenDialog from '../../components/FullScreenDialog';
import Loader from '../../components/Loader';

import Wallet from './Wallet';

export default function CardBalance(props) {
  const { cards } = useOutletContext();
  const { cardId } = useParams();

  const card = _.find(cards, c => c.id == cardId);

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState([]);
  const [walletOpen, setWalletOpen] = React.useState(false);

  const onClose = () => navigate("../", {replace: true});

  React.useEffect(() => {
    fetchData(cardId).finally(() => setLoading(false));
  }, [cardId]);

  const fetchData = async id => {
    try {
      fetchTransactions(id)
    } catch(err) {

    }
  }

  const fetchTransactions = async id => {
    const { data } = await request.get(`cards/${id}/transactions`)
    setTransactions(
      _.map(data, t => {
        return {
          id: t.id,
          ...t.attributes
        }
      })
    )
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          (loading || card == null) ?
            <Loader /> :
            <Box>
              <Box mb={3}>
                <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={2}>
                  <Box>
                    <Typography variant="body4" fontWeight={600}>
                      Initial Balance
                    </Typography>
                    <Typography variant="h2" fontWeight={600}>
                      { currencyFormatter(card.amount, 2) }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body4" fontWeight={600}>
                      Current Balance
                    </Typography>
                    <Typography variant="h2" fontWeight={600}>
                      { currencyFormatter(card.balance || card.amount, 2) }
                    </Typography>
                  </Box>

                </Stack>
              </Box>

              <Divider />

              <Box mt={3}>
                <Stack spacing={1}>
                  {
                    _.map(transactions, transaction => {
                      return (
                        <Paper variant="outlined" key={transaction.id} sx={{p: 2}}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Typography variant="body3" fontWeight={600}>
                                {
                                  currencyFormatter(transaction.amount, 2)
                                }
                              </Typography>
                              <Typography variant="body3">
                               { transaction.merchant_name }
                              </Typography>
                              <Typography variant="body3">
                                {
                                  dates.tzAwareDateTime(
                                    transaction.authorized_at ||
                                      transaction.created_at
                                  )
                                }
                              </Typography>
                            </Box>

                            <Chip
                              size="small"
                              color={
                                transaction.status == "declined" ? "error" : "default"
                              }
                              label={
                                <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                                  {transaction.status}
                                </Typography>
                              } />
                          </Stack>
                        </Paper>
                      )
                    })
                  }
                </Stack>
              </Box>
            </Box>
        }


      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <Button variant="contained" onClick={() => setWalletOpen(true)}>
          Add to Mobile Wallet
        </Button>
      </DialogActions>

      <Wallet
        {...props}
        {...card}
        open={walletOpen}
        onClose={() => setWalletOpen(false)} />

    </FullScreenDialog>
  );
}
