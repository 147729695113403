import { createTheme, responsiveFontSizes } from '@mui/material/styles';


const darkBlue = "#141c3f";
const lightBlue = "#177bc7";
const secondary = "#55d099";
const primary = "#2c7fc9";
const black = "#343a40";
const white = "#fff";
const darkBlack = "rgb(36, 40, 44)";
const background = "#f5f5f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

let theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    darkBlue,
    gray: {
      main: '#bdbdbd',
    },
    common: {
      black,
      lightBlue,
      white,
      background,
      darkBlue
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
      error: "rgb(255, 48, 65)",
      info: "#0288d1"
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background
    },
    spacing
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs
    }
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static"
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiAlert: {
      root: {
        action: {
          padding: 0
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'GeneralSans',
      'Urbanist',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.2
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1.3
    },
    h3: {
      fontSize: 26,
      fontWeight: 600
    },
    h4: {
      fontSize: 24,
      lineHeight: 1.6
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.6
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 18,
      lineHeight: 1.3,
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.3,
      display: "block"
    },
    body3: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
      display: "block"
    },
    body4: {
      fontSize: 12,
      lineHeight: "20px",
      display: "block"

    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      `,
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          fontWeight: 600,
        },
        containedPrimary: {
          fontWeight: 600,
        },
        containedSecondary: {
          fontWeight: 600,
          color: '#ffffff', // Ensure the text color is white
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0
        }
      }
    },
  },
});

// theme.typography.h3 = {
//   fontSize: '1.2rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.4rem',
//   },
// };

export default responsiveFontSizes(theme);