import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as offerActions from '../../actions/offers';
import * as cardActions from '../../actions/cards';
import FullScreenDialog from '../../components/FullScreenDialog';

import OffersCreate from '../offers/Create';
import OffersCreateError from '../offers/CreateError';

import {useEffect} from "react";
import {usePostHog} from "posthog-js/react";

function FeedItemDetails(props) {
  const {
    cards,
    fetchCards,
    feedItems,
    offers,
    createOffer,
    picks,
    teams,
    user,
    params,
    history
  } = props;

  const posthog = usePostHog();

  const [storyOpen, setStoryOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeProposition, setActiveProposition] = React.useState(null);

  const toggleErrorOpen = () => setErrorOpen(!errorOpen);
  const toggleStory = () => setStoryOpen(!storyOpen);

  const feedItem = _.find(feedItems, fi => fi.feedItem.id == params.feedItemId);

  if (_.isEmpty(feedItem)) return <Box />;

  const { story, title, startAt, imageUrl, feedableType, propositions } = feedItem;
  const started = time.getTimeTill(startAt) == "Expired";

  const pick = _.last(picks);

  const onClose = () => history.replace("../../");

  const userFavoriteTeams = _.chain(user.favorites)
    .filter(f => f.favoritable_type === "Team")
    .map(f => f.favoritable_id)
    .value()

  const relevantTeams = feedableType === "SportsEvent" ?
    _.chain(propositions)
      .groupBy("teamId")
      .keys()
      .map(id => _.find(teams, t => t.id === id))
      .filter(_.identity)
      .sortBy(t => {
        return _.includes(userFavoriteTeams, t.id) ? 0 : Number.MAX_VALUE;
      })
      .value() :
      [];

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle sx={{
        p: 0,
        height: 205,
        position: "relative",
        backgroundColor: "rgb(20, 28, 63)",
        ...(feedableType === "SportsEvent" ? {
          backgroundImage:
            `url(${_.get(relevantTeams, '[0].logo_url', '')}),
             linear-gradient(to right, transparent 49.5%, black 49.5%, black 50.5%, transparent 50.5%),
             url(${_.get(relevantTeams, '[1].logo_url', '')})`,
          backgroundPosition: 'left center, center center, right center',
          backgroundSize: '50% 100%, auto, 50% 100%',
          backgroundRepeat: 'no-repeat',
        } : {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }),
      }}>
          <Box p={2} sx={{
            position: "absolute",
            top: !!story ? 45 : 125,
            left: 0,
            right: 45,
            background: "rgba(29, 48, 121, 0.8)",
            borderRadius: 2,
            zIndex: 10000000
          }}>
            <Typography variant="h6" fontWeight={700} sx={{
              lineHeight: 1.3,
              fontSize: "20px",
              color: "rgba(255, 255, 255, 1)",
              textTransform: "uppercase",
            }}>
              {
                title
              }
            </Typography>

            <Typography mt={0.5} variant="body3" fontWeight={600} color="rgba(255, 255, 255, 1)">
              {
                started ?
                  "This event has started" :
                  `Ends ${dates.tzAwareDateTime(startAt, 'dddd, hh:mm A')}`
              }
            </Typography>


            {
              !!story &&
                <Box onClick={toggleStory}>
                  <Typography mt={3} variant="body2" fontWeight={400} color="rgba(255, 255, 255, 1)" style={{
                    fontSize: 20
                  }}>
                    {
                      _.truncate(
                        story, {
                          'length': storyOpen ? story.length : 32,
                          'omission': '...'
                        }
                      )

                    }

                  </Typography>


                  <Stack alignItems="center" justifyContent="center">
                    <IconButton onClick={toggleStory}>
                      {
                        storyOpen ?
                        <ArrowUpwardIcon sx={{
                            color: "#fff",
                              fontSize: 25
                            }} />  :
                          <ArrowDownwardIcon sx={{
                            color: "#fff",
                              fontSize: 25
                            }} />
                      }
                    </IconButton>
                  </Stack>
              </Box>
            }
        </Box>

      </DialogTitle>
      <DialogContent sx={{
        position: "relative",
        background: "#141c3f",
        p: 2,
      }}>

        <Box mt={6}>
          <Box mt={!!story ? 8 : 4}>
            <Stack spacing={1.5}>
              {
                relevantTeams.length > 0 &&
                  <Tabs value={activeTab} onChange={(e, tab) => {
                    setActiveTab(tab)
                  }}>
                    {
                      _.map(relevantTeams, t => {
                        return (
                          <Tab key={t} sx={{
                            color: "#fff",
                            textTransform: "inherit",
                            fontWeight: 600,
                            fontSize: 14
                          }} label={
                            _.capitalize(t.name)
                          }  />
                        )
                      })
                    }
                  </Tabs>
              }

              {
                _.chain(propositions)
                  .filter(p => {
                    const team = relevantTeams[activeTab]
                    if (!!team) {
                      return team.id == p.teamId
                    } else {
                      return true
                    }
                  })
                  .map((proposition, i) => {
                    const offer = _.find(offers, o => o.proposition_id == proposition.id);

                    const prize = pick == null ?
                      0 :
                      _.min([
                        pick.expected_value_cents / proposition.propositionProbabilities[0].probability,
                        1000
                      ]) / 100

                    return (
                      <ClickablePaper sx={{px: 2}} key={i} sx={{p: 2}} onClick={() => {
                        if (!!offer) {
                          history.push(`./${offer.id}`)
                        } else if (pick == null) {
                          setErrorOpen(true)
                        } else {
                          setActiveProposition(proposition)
                        }
                      }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                          <Box>

                            <Typography variant="body4" fontWeight={700} textTransform="uppercase">
                              Cash Back
                            </Typography>

                            <Typography variant="body2" fontWeight={500} lineHeight={1.3}>
                              If {
                                proposition.winDescription
                              }
                            </Typography>
                          </Box>
                          {
                            !!offer ?
                              <IconButton variant="contained" size="small" sx={{
                                fontSize: 11
                              }}>
                                <CheckIcon />
                              </IconButton> :
                              <IconButton variant="contained" size="small" sx={{
                                fontSize: 11
                              }}>
                              </IconButton>
                          }
                        </Stack>
                      </ClickablePaper>
                    )
                  })
                  .value()
              }

            </Stack>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{
        p: 2,
        backgroundColor: "#141c3f",
        justifyContent: "start"
      }}>
        <Button onClick={onClose} startIcon={<ArrowBackIosNewIcon />} sx={{color: "#fff", fontWeight: 600}}>
          Back
        </Button>
      </DialogActions>

      <Outlet />

      <OffersCreateError
        {...props}
        open={errorOpen}
        onClose={toggleErrorOpen} />

      <OffersCreate
        {...props}
        pick={pick}
        open={activeProposition !== null}
        onClose={() => setActiveProposition(null)}
        createOffer={createOffer}
        feedItem={feedItem}
        proposition={activeProposition} />
    </FullScreenDialog>

  )
}


const select = $$state => _.pick($$state, ["user", "offers", "cards", "teams", "feedItems", "picks"]);
export default connect(select, {
  ...offerActions,
  ...cardActions,
})(withRouter(FeedItemDetails));
