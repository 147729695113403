import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as offersActions from '../../actions/offers';
import request from '../../utils/request';

import TopNavBar from '../marketing/TopNavBar';
import Footer from '../marketing/Footer';
import Loader from '../../components/Loader';

function MerchantsMain(props) {
  const {
    user,
    history,
    location,
  } = props;

  return (
    <Box>
      <TopNavBar
        user={user}
        history={history}
        location={location} />

      <Box sx={{
        minHeight: "calc(100vh - 150px)",
      }}>
        <Outlet context={props} />
      </Box>


      <Footer />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "offers"]);
export default connect(select, {
  ...userActions,
  ...offersActions
})(withRouter(MerchantsMain));
