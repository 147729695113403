import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '../../components/TextField';

import withRouter from '../../utils/withRouter';
import * as userGroupActions from '../../actions/userGroups';

const emojis = [
  "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "🥲", "🥹", "☺️", "😊", "😇",
  "🙂", "🙃", "😉", "😌", "😍", "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝",
  "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🥸", "🤩", "🥳", "🙂‍", "😏", "😒", "🙂‍",
  "😞", "😔", "😟", "😕", "🙁", "😣", "😖", "😫", "😩", "🥺", "😢", "😭", "😮‍",
  "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓",
  "🫣", "🤗", "🫡", "🤔", "🫢", "🤭", "🤫", "🤥", "😶", "😶‍", "😐", "😑", "😬",
  "🫠", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱", "😴", "🤤", "😪", "😵", "😵‍",
  "🫥", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈", "👿",
  "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸",
  "😹", "😻", "😼", "😽", "🙀", "😿", "😾"
]

function UserGroupNew(props) {
  const { history, createUserGroup } = props;

  const [saving, setSaving] = React.useState(false);
  const [name, setName] = React.useState("");
  const [emoji, setEmoji] = React.useState("");

  const onClose = () => history.replace("../");

  const save = async () => {
    try {
      setSaving(true);

      const results = await createUserGroup({
        name,
        emoji
      })
      if (results.errors) {
        alert(results.errors.message)
      } else {
        onClose();
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="body1" fontWeight={600}>
            New Gravy Group
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            Pick a name for your group and you'll receive a link to invite friends.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{mt: 1}}>
          <TextField
            fullWidth
            name="name"
            value={name}
            onChange={e => setName(e.currentTarget.value)}
            label="Add a name" />
          <TextField
            select
            fullWidth
            name="emoji"
            value={emoji}
            onChange={(e, value) => {
              setEmoji(e.target.value)
            }}
            label="Pick an emoji">
            {emojis.map((option) => (
              <MenuItem key={option} value={option}>
                <Typography variant="h1" color="text.secondary">
                  {option}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton disabled={!name} loading={saving} onClick={save} variant="contained" color="primary">
          save
        </LoadingButton>
      </DialogActions>
    </Dialog>

  );
}

const select = $$state => _.pick($$state, "user", "userGroups");
export default connect(select, {
  ...userGroupActions
})(withRouter(UserGroupNew));
