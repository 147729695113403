import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import Loader from '../../components/Loader';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import FidelConnect from '../../components/FidelConnect';

function CardsNew(props) {
  const { user, savePlaidUser, history } = props;

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("../");

  const connectToPlaid = async token => {
    try {
      setSaving(true)
      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.href = "/app/account/cards/"
      }
    } catch(err) {
      console.log(err)
      alert("Unknown error please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          saving ?
            <Loader /> :
            <Box>
              <Box mb={3}>
                <Typography variant="body1" fontWeight={600}>
                  Receive a Pick instantly
                </Typography>
                <Typography variant="body2">
                  One Pick on us just for connecting your
                  credit card.
                </Typography>
              </Box>

              {
                false &&
                  <Box mb={4}>
                    <FidelConnect user={user} onSuccess={onClose} />
                  </Box>

              }

              {
                false &&
                  <Divider>
                    <Typography variant="body2" fontWeight={600}>Or</Typography>
                  </Divider>
              }


              <Box mt={3} mb={2} width="100%">
                <PlaidLinkButton fullWidth onSuccess={connectToPlaid} />
              </Box>
            </Box>
        }

      </DialogContent>

      <Outlet />
    </Dialog>

  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions
})(withRouter(CardsNew));
