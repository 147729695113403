const initialState = {
  alerts: [],
  cards: [],
  integrations: [],
  notifications: [],
  offers: [],
  referrals: [],
  regions: [],
  sports: [],
  feedItems: [],
  leagues: [],
  merchantOffers: [],
  players: [],
  picks: [],
  propositions: [],
  teams: [],
  transactions: [],
  user: {},
  userGroups: []
};

export default initialState;
