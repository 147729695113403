import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import * as userActions from '../../actions/user';
import * as referralActions from '../../actions/referrals';
import withRouter from '../../utils/withRouter';

import Link from '../../components/Link';

export default function AccountReferrals(props) {
  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Referrals
    </Typography>,
  ];

  return (
    <Box pb={12}>
      <Container maxWidth="sm">
        <Box mb={4}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" fontWeight={600} lineHeight={1.3}>
            Refer Friends, Receive Picks.
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            For each friend who becomes a Gravy user,
            you'll get an extra Pick.
          </Typography>
        </Box>

        <Outlet />
      </Container>
    </Box>
  );
}
