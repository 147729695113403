import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

import * as analytics from "../utils/analytics";

export const setUser = user => {
  window.localStorage.setItem("user", JSON.stringify(user));

  return {
    type: actionTypes.user.SHOW,
    payload: {
      user
    },
  };
};

export const updateUser = user => {
  return {
    type: actionTypes.user.UPDATE,
    payload: {
      user
    },
  };
};

export const login = value => {
  return async (dispatch, getState) => {
    const { data } = await request.post("user_sessions", value);

    if (!data.errors) {
      const user = {
        id: data.id,
        jwt: data.jwt,
        ...data.attributes
      };

      dispatch(setUser(user))
    }

    return data;
  }
};

const clearUserAndReloadPage = () => {
  window.localStorage.removeItem("user");
  window.location.reload();
  window.location.href = "/";
}

export const logout = value => {
  return async (dispatch, getState) => {
    const { data } = await request.post("user_sessions/logout");
    dispatch(setUser({}));

    clearUserAndReloadPage();

    // dispatch({
    //   type: actionTypes.user.DELETE,
    //   payload: {}
    // })
  }
};

export const pingUser = user => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`users/ping`);

    if (data.errors && data.errors.message == "Not authenticated") {
      clearUserAndReloadPage()
    } else {
      dispatch(setUser({
        ...user,
        ...data.attributes
      }));
    }
  }
};

export const createUser = (values) => {
  return async (dispatch, getState) => {
    const { data } = await request.post("users", {
      ...values,
      role: "shopper",
      user_group_id: window.localStorage.getItem('user_group_id'),
      referrer_id: window.localStorage.getItem('referralToken')
    });

    if (!data.errors) {
      const user = {
        id: data.id,
        jwt: data.jwt,
        ...data.attributes
      }

      dispatch(setUser(user));
    }

    return data;
  }
};

export const activateUser = (user, code) => {
  return async (dispatch, getState) => {
    const { data } = await request.post("users/activate", {
      phone: user.phone,
      code,
    });

    if (!data.errors) dispatch(
      setUser({
        ...user,
        status: "activated"
      })
    )

    return data;
  }
};

export const saveUser = (user, values) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`users/${user.id}`, values)

    if (!data.errors) {
      dispatch(
        setUser({
          ...user,
          ...data.attributes
        })
      )
    }

    return data;
  }
}

export const resendActivationCode = phone => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`users/send_activation`, { phone })

    if (!data.errors) {

    }

    return data;
  }
}

export const savePlaidUser = (user, public_token) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`plaid/access_token`, { public_token })
    if (!data.errors) {

      analytics.logEvent("PlaidUser Created");

      dispatch(
        setUser({
          ...user,
          plaid_auser: data
        })
      )
    }

    return data
  }
}

export const destroyPlaidUser = user => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`plaid/destroy_access_token`)
    if (!data.errors) {

      analytics.logEvent("PlaidUser Destroyed");

      dispatch(
        setUser({
          ...user,
          plaid_user: null
        })
      )
    }

    return data
  }
}

export const saveFavorites = (user, favorites) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`favorites`, {favorites})
    if (!data.errors) {

      dispatch(
        setUser({
          ...user,
          favorites: _.map(data, f => {
            return {
              id: f.id,
              ...f.attributes
            }
          })
        })
      )
    }

    return data
  }
}
