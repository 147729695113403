import _ from "lodash";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import * as dates from "../../utils/dates";
import ClickablePaper from "../../components/ClickablePaper";
import { useEffect } from "react";

export default function FeedItemCard(props) {
  const {feedItem: { title, startAt, imageUrl, feedableType, propositions }, user, teams} = props;

  const userFavoriteTeams = _.chain(user.favorites)
    .filter(f => f.favoritable_type === "Team")
    .map(f => f.favoritable_id)
    .value()

  const relevantTeams = feedableType === "SportsEvent" ?
    _.chain(propositions)
      .groupBy("teamId")
      .keys()
      .map(id => _.find(teams, t => t.id === id))
      .filter(_.identity)
      .sortBy(t => {
        return _.includes(userFavoriteTeams, t.id) ? 0 : Number.MAX_VALUE;
      })
      .value() :
      [];

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "rgb(20, 28, 63)",
        ...(feedableType === "SportsEvent" ? {
          backgroundImage:
            `url(${_.get(relevantTeams, '[0].logo_url', '')}),
             linear-gradient(to right, transparent 49.5%, black 49.5%, black 50.5%, transparent 50.5%),
             url(${_.get(relevantTeams, '[1].logo_url', '')})`,
          backgroundPosition: 'left center, center center, right center',
          backgroundSize: '50% 100%, auto, 50% 100%',
          backgroundRepeat: 'no-repeat',
        } : {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }),
        cursor: "pointer",
        minHeight: {
          xs: 165,
          md: 300,
        },
        position: "relative",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          background: "rgba(29, 48, 121, 0.75)",
          position: "absolute",
          bottom: 10,
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={700}
          gutterBottom
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            lineHeight: 1.2,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography
          textTransform="uppercase"
          variant="body4"
          fontWeight={700}
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            lineHeight: 1.2,
          }}
        >
          Offers end {dates.tzAwareDateTime(startAt, "MM/DD")}
        </Typography>
      </Box>
    </Paper>
  );
}
