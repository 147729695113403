import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Auth from './Auth';
import * as userActions from '../../actions/user';

function UserAuthButton(props) {
  const { user, screen, onAuthentication } = props;

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  let children = React.cloneElement(props.children, {
    onClick: () => {
      if (_.isEmpty(user) || user.status == "unregistered") {
        setOpen(true)
      } else if (onAuthentication != null) {
        onAuthentication()
      } else {
        navigate(`/app`)
      }
    }
  })

  return (
    <Box>
      <Auth
        {...props}
        open={open}
        user={user}
        screen={screen}
        onClose={() => setOpen(false)} />

      {children}
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, userActions)(UserAuthButton);
